import * as yup from 'yup';

const passwordRegExp = /^[^ ]*$/;

const nameRegExp = /^(?=.{1,60}$)[a-zA-Z æøåÆØÅ]+(?:[-' ][a-zA-Z æøåÆØÅ]+)*$/;
export const validateRequiredEmail = () =>
  yup.string().trim().email('Email is not valid').required('Required');

export const validateRequiredPassword = () =>
  yup
    .string()
    .matches(passwordRegExp, 'Space not allowed')
    .min(8, 'Password should have min. 8 characters')
    .required('Required');

export const validateRequiredConfirmPassword = () =>
  yup
    .string()
    .matches(passwordRegExp, 'Space not allowed')
    .min(8, 'Password should have min. 8 characters')
    .required('Required');

export const validateRequiredFirstName = () =>
  yup
    .string()
    .trim()
    .matches(nameRegExp, 'First Name is not valid')
    .required('Required')
    .min(2, 'First Name must be between 2 to 32 characters');

export const validateRequiredLastName = () =>
  yup
    .string()
    .trim()
    .matches(nameRegExp, 'Last Name is not valid')
    .required('Required')
    .min(2, 'First Name must be between 2 to 32 characters');
export const validateRequiredDOB = () =>
  yup.string().required('validation.dob.required').nullable();

export const validateRequired = () => yup.string().required('validation.required').nullable();
