import React, { useState } from 'react';

import useReward from 'contexts/RewardsContext';
import useBalance from 'contexts/BalanceContext';
import MyRewardCard from './components/myRewardCard';

import { convertFromWei } from 'utils/utility';
import ExitPoolModal from './components/ExitPoolModal';

const MyRewards = () => {
  const {
    actions: { exitPoolHandler, redeemMGTTokensHandler, redeemMYTTokensHandler },
  } = useReward();

  const {
    state: { MCTBalance, MGTBalance, MYTBalance, totalPoolBalance },
  } = useBalance();
  const [MGTRedeemLoading, setMGTRedeemLoading] = useState(false);
  const [MYTRedeemLoading, setMYTRedeemLoading] = useState(false);
  const [exitPoolLoading, SetexitPoolLoading] = useState(false);

  const [exitPoolModal, showExitPoolModal] = useState(false);

  const handleConfirm = async (amount) => {
    await exitPoolHandler(amount);
    showExitPoolModal(false);
  };
  const handleCancel = () => {
    showExitPoolModal(false);
    SetexitPoolLoading(false);
  };

  const handleMGTRedeem = async () => {
    redeemMGTTokensHandler(setMGTRedeemLoading);
  };
  const handleMYTRedeem = async () => {
    redeemMYTTokensHandler(setMYTRedeemLoading);
  };
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 pb-5 border-b-2 border-secondary">
        <MyRewardCard
          data={{
            heading: 'USD2',
            loading: exitPoolLoading,
            buttonText: 'Exit Pool',
            lockedLabel: 'Locked',
            lockedAmount: convertFromWei(MCTBalance?.lockedMCT) || 0,
            unLockedLabel: 'UnLocked',
            unlockedAmount: convertFromWei(MCTBalance?.unlockedMCT) || 0,
          }}
          disabled={Number(totalPoolBalance) === 0 || exitPoolLoading}
          onSubmit={() => showExitPoolModal(true)}
        />

        <MyRewardCard
          data={{
            heading: 'FEES',
            loading: MYTRedeemLoading,
            buttonText: 'Redeem',
            lockedLabel: 'Claimed',
            lockedAmount: MYTBalance?.claimedMYTBalance || 0,
            unLockedLabel: 'UnClaimed',
            unlockedAmount: MYTBalance?.unClaimedMYTBalance || 0,
          }}
          onSubmit={handleMYTRedeem}
        />
        <MyRewardCard
          data={{
            heading: 'DFL',
            loading: MGTRedeemLoading,
            buttonText: 'Redeem',
            lockedLabel: 'Claimed',
            lockedAmount: MGTBalance?.claimedMGTBalance || 0,
            unLockedLabel: 'UnClaimed',
            unlockedAmount: MGTBalance?.unClaimedMGTBalance || 0,
          }}
          onSubmit={handleMGTRedeem}
        />
      </div>
      <div>
        <ExitPoolModal handleConfirm={handleConfirm} open={exitPoolModal} onClose={handleCancel} />
      </div>
    </>
  );
};

export default MyRewards;
