import React from 'react';
import { Text } from 'molecules/Text';
import { sideTabs } from 'utils/utility';
import useAuth from 'contexts/AuthenticationContext';

export const SideNavbar = () => {
  const {
    state: { sideTab },
    actions: { sideTabHandler },
  } = useAuth();
  return (
    <nav className="space-y-8 mt-5  flex flex-col items-center ">
      {sideTabs.map((val, i) => (
        <>
          <div
            key={i}
            onClick={() => sideTabHandler(val.link)}
            className={`bg-secondary p-3  flex justify-center relative border-gray-100 rounded-1 w-60  text-center  hover:border-4 hover:shadow-corner hover:bg-lightBlue cursor-pointer
        ${sideTab === val.link && 'shadow-corner bg-lightBlue '}`}
          >
            <Text className=" font-medium text-white  text-base">{val.textid}</Text>

            {/* {sideTab === val.link && (
              <ChevronRightIcon className=" h-6 w-6 text-white absolute right-2" />
            )} */}
          </div>
          {val.textid === 'Staking' && (
            <div className="w-60  bg-secondary shadow-corner " style={{ height: '1px' }}></div>
          )}
        </>
      ))}
    </nav>
  );
};
