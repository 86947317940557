import React from 'react';
import ReactPlayer from 'react-player';
import Modal from 'molecules/LandingModalTemplate';
import { CheckIcon } from '@heroicons/react/outline';

const ComponentsSectionModal = ({ onClose, open }) => {
  const videoUrl = `https://www.youtube.com/watch?v=iTN_W72CFTo`;

  return (
    <Modal open={open} onClose={onClose} headerText="Key Components of DFL">
      <div className="mx-auto max-w-auto md:max-w-7xl  text-gray-500 text-lg">
        <div>
          <div className=" md:w-1/2 shadow-out mx-auto mb-16 h-48 lg:h-80 mt-8 md:mt-0">
            <ReactPlayer
              autoPlay
              url={videoUrl}
              width="100%"
              height="100%"
              borderRadius="8px"
              config={{
                youtube: {
                  playerVars: { modestbranding: 1 },
                },
              }}
            />
          </div>
          <ul>
            <li className="flex mb-2">
              <span>
                <CheckIcon className="w-8 h-8 text-indigo-700 font-bold cursor-pointer" />
              </span>
              <span className="text-left font-thin text-gray-700 ml-2 text-lg">Funders </span>
            </li>
            <li className="flex">
              <span>
                <CheckIcon className="w-8 h-8 text-indigo-700 font-bold cursor-pointer" />
              </span>
              <span className="text-left font-regular ml-2 font-thin text-gray-700 text-lg">
                Networkers
              </span>
            </li>
            <li className="flex mb-2">
              <span>
                <CheckIcon className="w-8 h-8 text-indigo-700 font-bold cursor-pointer" />
              </span>
              <span className="text-left font-thin text-gray-700 ml-2 text-lg">
                Dev Strategists{' '}
              </span>
            </li>
            <li className="flex mb-2">
              <span>
                <CheckIcon className="w-8 h-8 text-indigo-700 font-bold cursor-pointer" />
              </span>
              <span className="text-left font-thin text-gray-700 ml-2 text-lg">Agents </span>
            </li>
            <li className="flex mb-2">
              <span>
                <CheckIcon className="w-8 h-8 text-indigo-700 font-bold cursor-pointer" />
              </span>
              <span className="text-left font-thin text-gray-700 ml-2 text-lg">Stakers </span>
            </li>
          </ul>
        </div>
        <div className="grid gap-5 mt-5">
          <p>
            <strong>Funders:</strong>
          </p>
          <p>
            The Funders are at the core of the whole system. They are the moneybags and come with
            the much-needed funds to buy a football club and help it come out of its financial woes.
          </p>
          <p>
            The Funders are advocates of the vision of the DECENTRALIZED FOOTBALL LEAGUE and firmly
            believe that this idea will work and achieve its intended goals and hence want to
            participate in it.
          </p>
          <p>
            The protocol will reward the funders by issuing yield tokens and they can get governance
            tokens through staking. This is a way of encouraging and acknowledging their support and
            trust.
          </p>
          <p>
            <strong>Networkers:</strong>
          </p>
          <p>
            The main goal of the protocol is to create a community of football aficionados who want
            to remain connected with the game in some way or the other.
          </p>
          <p>
            Real football lovers want to enjoy a real game of football and also generally interact
            with fellow fans at the same time by coming to the protocol and placing bets with other
            members of the community on real games using the escrow option (inbuilt in the
            protocol). As an incentive, they are rewarded with small amounts or percentages of
            protocol governance tokens.
          </p>
          <p>
            This kind of incentivizing arrangement will be limited to the initial stages only and
            withdrawn when the protocol funding process progresses and reaches the desired levels.
          </p>
          <p>
            The Networkers are one of the most important links in the protocol system as they help
            build the community. &nbsp;By using escrow, they can be a part of the protocol and get
            an insider view of how the system works.
          </p>
          <p>
            <strong>Dev Strategists: (Developers)</strong>
          </p>
          <p>
            Any freelance developer can be part of the protocol as long as the community accepts
            their proposal.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </p>
          <p>
            Developers can bring their own view of how to help improve the protocol.They can create
            more applications on top of the protocol. They are welcome to offer any proposals,
            suggest an additional application, any variations from the existing set-up, and
            generally anything that can help in achieving an enhancement of the existing protocol
            layout.
          </p>
          <p>
            The suggestions and propositions will be considered closely, their value weighed for
            feasibility, and the best ones will be voted up and recommended for inclusion within the
            system.
          </p>
          <p>
            The proposals will be accepted based on its merits and the value it brings to the
            protocol. The decentralized developer/strategists will get suitably rewarded for their
            input and efforts. The quantum of remuneration will be based on the decision of the
            community.
          </p>
          <p>
            Developers will also be apportioned salaries from the treasury. They will also be
            rewarded with small governance token options in drip fashion for creating new
            developments and strategies and for their efforts in improving the overall functionality
            of the protocol.
          </p>
          <p>The incentives and salaries will be decided solely by the DAO.</p>
          <p>
            <strong>Agents:</strong>
          </p>
          <p>
            Agents are people with experience and expertise in the game and the industry. They can
            be former administrators, managers, back-office management professionals, and anyone
            with any kind of deep connection with the game.
          </p>
          <p>
            They could be people well-versed with the game and the industry and command respect in
            the community. The agents play a vital role in the whole arrangement. They are assigned
            the crucial task of locating football owners looking for financial assistance and
            explain the working of the protocol to them. They get a handsome commission every time
            they bring in a football owner to join the protocol.
          </p>
          <p>
            We will be creating a wide network of multiple decentralized agents across the world to
            connect with football owners from all parts of the world and make them part of DFL.
          </p>
          <p>
            <strong>Stakers:</strong>
          </p>
          <p>
            Stakers are a group of people who believe in the long-term vision of the project. They
            come into the protocol with the inclination and the enthusiasm to remain invested for a
            long time.
          </p>
          <p>
            Staking works by placing governance tokens in a staking contract for a fixed term.
            Stakers keep getting rewards in a drip model.
          </p>
          <p>
            Stakers will also have the first option for enjoying various benefits that happen in the
            protocol. They are priority on everything. As they lock their tokens up in long term
            liquidity, they can enjoy a host of benefits such as
          </p>
          <ul className="list-disc ml-20">
            <li>P3 pools Participation</li>
            <li>Buying game moments (NFT)</li>
            <li>Joining digital play-to-win games</li>
            <li>Buying exclusive memorabilia</li>
            <li>Getting discounts to live real matches</li>
            <li>Getting invites to virtual clubs filled with cool</li>
            <li>
              The opportunity to socialize before watching football matches inside virtual stadiums
            </li>
          </ul>
          <p>
            <strong>Collateral Protocols:</strong>
          </p>
          <p>
            Before we go into the collateral protocol details, let’s have a broad understanding of
            the crypto industry. Those trying to get liquidity for football clubs must be aware of
            the fact that most of the liquidity in crypto are locked in the top two protocols i.e.:
            BTC and ETH together have a valuation of more one trillion dollars. In a highly volatile
            marketplace, the valuation of BTC and ETH can quickly scale two, three, or even five
            trillion dollars in a favorable environment.
          </p>
          <p>Here’s a brief view of how it works:</p>
          <p>
            The liquidity provider goes into a DEFI protocol and presents their crypto holdings as
            collateral, and borrows stablecoins. They are paid a fixed percentage of the total value
            of their crypto holding (say around 30 percent of the value).
          </p>
          <p>
            As time goes, if the underlying value of the tokens grows dramatically, they can borrow
            even more on their holdings. As the market gains, their liquidity holding will surge
            dramatically, creating a pool of immeasurable financial depth that empowers them to buy
            anything. They will have access to a literally endless flow of liquid cash.
          </p>
          <p>
            We will integrate major lending /borrowing protocols into DFL to facilitate users for
            inbuilt Collateral capability on their BTC/ETH holdings.
          </p>
          <p>
            They can then use that collateral which will&nbsp; be&nbsp; in the form of usd2 to enter
            p3 pools.
          </p>
          <p>
            In the future, club governance tokens can also be kept as collateral and can be used to
            enter p3 pools.
          </p>
          <p>
            <strong>Decentralized Autonomous Organization (DAO)</strong>
          </p>
          <p>
            The stakers are the official members of the DAO and have the authority to decide how the
            protocol will work. Their weightage inside the DAO is based on how much a participant
            has staked and for how long. Both the amount and the duration of the liquidity are taken
            into consideration while giving weightage. Stakers will have complete control of the
            management of the protocol. The DAO will constitute only of stakers and they will decide
            the direction the protocol will take as it grows and expands.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default ComponentsSectionModal;
