import React, { useRef } from 'react';
import * as yup from 'yup';
import { Button } from 'molecules/Button';

import { FormikInput } from 'molecules';
import { Form, Formik } from 'formik';

import useAuth from 'contexts/AuthenticationContext';

const ExitPoolFormValidationSchema = yup.object().shape({
  amount: yup
    .string()
    // .test(function (value) {
    //   if (Number(value) > Number(selectedCoinBalance)) {
    //     return this.createError({ message: 'Insufficient Balance' });
    //   } else return true;
    // })
    .required('Required*'),
});

const ExitPoolForm = ({ onSubmit, lockedBalance }) => {
  const {
    state: { submitLoading },
  } = useAuth();

  const formref = useRef();
  const handleMaxAmount = () => {
    formref?.current?.setFieldValue('amount', lockedBalance);
  };

  return (
    <Formik
      initialValues={{ amount: '' }}
      enableReinitialize={true}
      onSubmit={onSubmit}
      validationSchema={ExitPoolFormValidationSchema}
      innerRef={formref}
    >
      {({ errors }) => (
        <Form>
          <div className="mt-2 ">
            <div className="font-bold text-left text-white text-sm">
              <span className="text-gray-200 text-xs"> Balance:</span> {lockedBalance}
            </div>

            <div className=" text-black relative">
              <FormikInput name="amount" />
              <div
                className="absolute right-2 cursor-pointer border-2 top-2 border-gray-500 font-medium text-xs  p-1 text-black rounded-sm"
                onClick={handleMaxAmount}
              >
                Max
              </div>
            </div>
          </div>

          <div className="mt-4 flex justify-center">
            <Button type="submit" loading={submitLoading}>
              Withdraw Staked USD2
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ExitPoolForm;
