import React from 'react';
import { RotateLoader } from 'react-spinners';

export const Loader = ({ loading, size, children }) => {
  if (loading) {
    return (
      <div className="absolute inset-x-2/4 inset-y-2/4">
        <RotateLoader loading size={size || 11} color="#fff" />
      </div>
    );
  }
  return <>{children || null} </>;
};

Loader.defaultProps = {
  loading: true,
};
