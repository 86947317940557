import React from 'react';
import { Text } from 'molecules/Text';

import { ErrorMessage } from 'formik';

export const Error = ({ text }) => {
  return <Text className="text-red-600 mt-1 text-xs text-left">{text}</Text>;
};
export const FormikError = ({ name }) => {
  return <ErrorMessage name={name}>{(error) => <Error text={error} />}</ErrorMessage>;
};
