import axiosService from 'utils/api/axios';

const UserService = {
  account: function async(id) {
    return axiosService
      .get('/api/v1/account/' + id)
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  user: function async(text) {
    return axiosService
      .get('api/user/fetch-account-email-or-avatar/' + text)
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  userByUsername: function async(text) {
    return axiosService
      .get('api/v1/account/username/' + text)
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  avatar: function async(text) {
    return axiosService
      .get('api/user/fetch-account-by-avatar/' + text)
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetchAccount: function async(accountId) {
    return axiosService
      .get('api/user/update-account/' + accountId)
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

export default UserService;
