import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Image } from 'atoms/Image/Image';
import { useHistory } from 'react-router-dom';
import { Button } from 'molecules/Button';
import SideNavbar from 'molecules/SideNavbar';
import dummyProfile from 'assets/images/dummy-profile-image.png';
import useAuth from 'contexts/AuthenticationContext';
import { TransactionLoader } from 'atoms/TransactionLoader/TransactionLoader';
import logo from "assets/logo.jpeg"

export default function Header() {
  const {
    state: { user, authenticating, isLoggedIn },
    actions: { loadBlockchainData, setShowAuthModal, logout },
  } = useAuth();

  const hanldleLogin = () => {
    setShowAuthModal('signin');
  };
  const router = useHistory();
  // const logo =
  //   'https://dfl.xyz/wp-content/uploads/elementor/thumbs/DFL-Logo-white-pce85jdgcg7ioqu7xizw35qnsan6d8fuc30uz3xgcg.png';
  return (
    <>
      <Popover className="  w-screen text-white py-4  fixed bg-bgImage bg-no-repeat bg-cover z-50 ">
        {({ open }) => (
          <>
            <div className="mx-auto px-10 py-2 max-w-7xl">
              <div className="flex justify-between items-center  lg:space-x-8 md:space-x-2">
                <div className="flex justify-start ">
                  {/* <Image
                    src={logo}
                    alt="logo"
                    className="cursor-pointer w-24 md:w-40 md:ml-24"
                    onClick={() => router.push('/')}
                  /> */}
                  <h3    className="text-6xl font-extrabold text-white md:ml-24">DFL</h3>
                  {/* <span
                    className="m-auto ml-2 text-3xl font-bold text-gray-600 cursor-pointer"
                    onClick={() => router.push('/')}
                  >
                    DFL
                  </span> */}

                  {/* <div className="hidden md:flex justify-center justify-self-center w-full items-center font-bold text-xl space-x-6 pl-10 pt-1">
                    <NavLink
                      exact
                      to={appRoutes?.HOME}
                      className="lg:px-6 md:px-4  "
                      activeClassName="text-primary border-b-2 border-primary -mb-1"
                    >
                      <Text>Home</Text>
                    </NavLink>
                  </div> */}
                </div>
                <div className="-mr-2 -my-2 md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>

                <div className="hidden md:flex  items-center justify-end cursor-pointer">
                  {!user ? (
                    <>
                      <Button onClick={loadBlockchainData} loading={authenticating}>
                        Connect
                      </Button>

                      <span className="sr-only">Open user menu</span>
                    </>
                  ) : (
                    <div className="flex">
                      <div className=" rounded-full p-1 bg-gray-300 ">
                        <Image
                          className="h-8 w-10 rounded-full border border-primary"
                          src={user?.profileImage || dummyProfile}
                          alt="pankod"
                        />
                      </div>
                      <span className="m-auto ml-2 text-base font-bold text-white capitalize">
                        {user?.userName}
                      </span>
                    </div>
                  )}
                  {isLoggedIn ? (
                    <Button className="ml-3" onClick={logout}>
                      Logout
                    </Button>
                  ) : (
                    <Button className="ml-3" onClick={hanldleLogin}>
                      SignIn/SignUp
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-30"
              >
                <div className="rounded-lg pb-10 bg-bgImage shadow-lg ring-1 ring-black px-8 ring-opacity-5 bg-white divide-y-2 divide-gray-50 z-30">
                  <div className="pt-5 pb-6 ">
                    <div className="flex items-center justify-between">
                      <div className="flex flex-row">
                        {/* <Image
                          src={logo}
                          alt="logo"
                          className="cursor-pointer w-40"
                          onClick={() => router.push('/')}
                        /> */}
                        <span className="m-auto text-4xl font-extrabold text-white">DFL</span>
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Close menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                  </div>

                  {/* <div className="flex flex-col text-black ">
                    <NavLink
                      exact
                      to={appRoutes?.HOME}
                      className="text-xl font-medium py-2 hover:text-primary"
                      activeClassName="text-primary"
                    >
                      <Text>Home</Text>
                    </NavLink>
                  </div> */}

                  <div className="flex flex-col py-2">
                    {!user ? (
                      <>
                        <Button onClick={loadBlockchainData} loading={authenticating}>
                          Connect
                        </Button>

                        <span className="sr-only">Open user menu</span>
                      </>
                    ) : (
                      <div className="flex justify-center items-center">
                        <div className=" rounded-full p-1 bg-gray-300 ">
                          <Image
                            className="h-8 w-10 rounded-full border border-primary"
                            src={user?.profileImage || dummyProfile}
                            alt="pankod"
                          />
                        </div>
                        <span className=" ml-2 text-base font-bold text-white capitalize">
                          {user?.userName}
                        </span>
                      </div>
                    )}
                    {isLoggedIn ? (
                      <Button className="ml-3" onClick={logout}>
                        Logout
                      </Button>
                    ) : (
                      <Button className="ml-3" onClick={hanldleLogin}>
                        SignIn/SignUp
                      </Button>
                    )}{' '}
                    <SideNavbar />
                  </div>
                  {/* <Link to="/profile">
                    <div className="py-4 px-5 flex flex-row">
                      <span className="sr-only">Open user menu</span>

                      <Image
                        className="h-8 w-8 rounded-full"
                        src={user?.profileImage || dummyProfile}
                        alt="pankod"
                        width="20"
                        height="20"
                      />
                      <span className="pl-2 pt-1 m-auto ml-2 text-base font-medium text-gray-600">
                        {user?.firstName + " " + user?.lastName}
                      </span>
                    </div>
                  </Link> */}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <TransactionLoader />
    </>
  );
}
