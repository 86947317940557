export const AUTH_TOKEN_COOKIE = 'auth-token';
export const REFRESH_TOKEN_COOKIE = 'refresh-token';
export const ACCESS_TOKEN = 'access_token';
export const USER_TOKEN = 'user_token';
export const USER_ID = 'user_id';

export function getAccessToken() {
  const value = localStorage.getItem('LOGIN_USER');
  const data = JSON.parse(value);
  return data?.tokenData?.token;
}
