import React, { Suspense, lazy } from 'react';

import { Loader } from 'atoms/Loader';
import useAuth from 'contexts/AuthenticationContext';

const LockedValuesPage = lazy(() =>
  import(/* webpackChunkName: 'LockedValuesPage' */ 'modules/LockedValuesPage'),
);

const Faucets = lazy(() => import(/* webpackChunkName: 'Faucets' */ 'modules/Faucets'));

const Deposit = lazy(() => import(/* webpackChunkName: 'Deposit' */ 'modules/Deposit'));

const Staking = lazy(() => import(/* webpackChunkName: 'Staking' */ 'modules/Staking'));

const Tasklist = lazy(() => import(/* webpackChunkName: 'Tasklist' */ 'modules/Tasklist'));
const Escrow = lazy(() => import(/* webpackChunkName: 'Escrow' */ 'modules/Escrow'));
const TransactionSection = lazy(() =>
  import(/* webpackChunkName: 'Tasklist' */ 'modules/TransactionSection'),
);

const BugReport = lazy(() => import(/* webpackChunkName: 'BugReport' */ 'modules/BugReport'));

export const UnauthenticatedRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <LockedValuesPage />
    </Suspense>
  );
};

export const AdminRoutes = () => {
  return <Suspense fallback={<Loader />}></Suspense>;
};

export const AuthenticatedRoutes = React.memo(() => {
  const {
    state: { sideTab },
  } = useAuth();

  switch (sideTab) {
    case 0:
      return (
        <Suspense fallback={<Loader />}>
          <div className=" shadow-out py-5 md:mx-10 mx-auto text-white mt-5">
            <Faucets />
          </div>
        </Suspense>
      );
    case 1:
      return (
        <Suspense fallback={<Loader />}>
          <div className=" shadow-out py-5 md:mx-10 mx-auto text-white mt-5">
            <Deposit />
          </div>
        </Suspense>
      );
    case 2:
      return (
        <Suspense fallback={<Loader />}>
          <div className=" shadow-out py-5 mx-auto text-white mt-5">
            <Staking />
          </div>
        </Suspense>
      );
    case 3:
      return (
        <Suspense fallback={<Loader />}>
          <div className=" shadow-out py-5 md:mx-10 mx-auto text-white mt-5">
            <Escrow />
          </div>
        </Suspense>
      );

    case 4:
      return (
        <Suspense fallback={<Loader />}>
          <div className=" shadow-out py-5 mx-auto text-white mt-5">
            <Tasklist />
          </div>
        </Suspense>
      );
    case 5:
      return (
        <Suspense fallback={<Loader />}>
          <div className=" shadow-out py-5 mx-auto text-white mb-5">
            <TransactionSection />
          </div>
        </Suspense>
      );
    case 6:
      return (
        <Suspense fallback={<Loader />}>
          <div className=" shadow-out py-5 mx-auto text-white mb-5">
            <BugReport />
          </div>
        </Suspense>
      );

    default:
      return null;
  }
});
