import { useReducer } from 'react';
import { createContainer, createReducer } from 'utils/context';
import useAuth from './AuthenticationContext';
import useBalance from './BalanceContext';
import { toast } from 'react-toastify';

import ethAddressConfig from 'utils/abiSection/address/ethAddressConfig';

import {
  StakeLPContractABI,
  stakeGovABI,
  escrowABI,
  stakeABI,
  depositABI,
} from 'utils/abiSection/abis';

const initialState = {};

const rewardReducer = createReducer({});

export const { useContext: useReward, Provider: RewardProvider } = createContainer(() => {
  const [state] = useReducer(rewardReducer, initialState);

  const {
    state: { accountId },
    actions: { setTransactionLoader, setSubmitLoading },
  } = useAuth();
  const {
    actions: { getAllUnlockedBalance },
  } = useBalance();

  const redeemMGTTokensHandler = async (setMGTRedeemLoading) => {
    const web3 = window.web3;

    if (web3 !== undefined && web3.eth !== undefined) {
      let stakeLPContractObject = new web3.eth.Contract(
        StakeLPContractABI,
        ethAddressConfig.STAKE_LP_CONTRACT_ADDRESS,
      );
      let stakeGovABIObject = new web3.eth.Contract(stakeGovABI, ethAddressConfig.gov_address);
      let escrowABIObject = new web3.eth.Contract(escrowABI, ethAddressConfig.escrow_Address);

      try {
        setMGTRedeemLoading(true);

        await stakeLPContractObject.methods
          .claimRewards(accountId, 0)
          .send({ from: accountId })
          .on('transactionHash', (hash) => {
            setTransactionLoader(true);
          })
          .then(async (receipt) => {
            if (receipt.status) {
              setTransactionLoader(false);
            } else {
              setTransactionLoader(false);
              toast.error('Transaction Failed');
            }
          });
        await stakeLPContractObject.methods
          .claimRewards(accountId, 1)
          .send({ from: accountId })
          .on('transactionHash', (hash) => {
            setTransactionLoader(true);
          })
          .then(async (receipt) => {
            if (receipt.status) {
              setTransactionLoader(false);
            } else {
              setTransactionLoader(false);
              toast.error('Transaction Failed');
            }
          });

        await stakeGovABIObject.methods
          .claimRewards(accountId)
          .send({ from: accountId })
          .on('transactionHash', (hash) => {
            setTransactionLoader(true);
          })
          .then(async (receipt) => {
            if (receipt.status) {
              setTransactionLoader(false);
            } else {
              setTransactionLoader(false);
              toast.error('Transaction Failed');
            }
          });

        await escrowABIObject.methods
          .claimRewards(accountId)
          .send({ from: accountId })
          .on('transactionHash', (hash) => {
            setTransactionLoader(true);
          })
          .then(async (receipt) => {
            if (receipt.status) {
              toast.success('Transaction Success');
              setMGTRedeemLoading(false);

              setTransactionLoader(false);
              await getAllUnlockedBalance();
            } else {
              setTransactionLoader(false);
              toast.error('Transaction Failed');
            }
          });
      } catch (err) {
        setTransactionLoader(false);
        setMGTRedeemLoading(false);

        toast.error('Transaction Failed');
        toast.error(err.message);
      }
    } else {
      setTransactionLoader(false);
      setMGTRedeemLoading(false);
    }
  };

  const redeemMYTTokensHandler = async (setMYTRedeemLoading) => {
    const web3 = window.web3;
    if (web3 !== undefined && web3.eth !== undefined) {
      let stakeABIObject = new web3.eth.Contract(stakeABI, ethAddressConfig.stake_address);
      setMYTRedeemLoading(true);
      setTransactionLoader(true);
      try {
        await stakeABIObject.methods
          .claimRewards(accountId)
          .send({ from: accountId })
          .then(async (receipt) => {
            if (receipt.status) {
              toast.success('Transaction Success');
              setMYTRedeemLoading(false);
              setTransactionLoader(false);
              await getAllUnlockedBalance();
            } else {
              setTransactionLoader(false);
              setMYTRedeemLoading(false);
              toast.error('Transaction Failed');
            }
          });
      } catch (err) {
        setTransactionLoader(false);
        setMYTRedeemLoading(false);

        toast.error('Transaction Failed');
        toast.error(err.message);
      }
    } else {
      setMYTRedeemLoading(false);
    }
  };

  const exitPoolHandler = async (amount) => {
    const web3 = window.web3;
    if (web3 !== undefined && web3.eth !== undefined) {
      let depositABIObject = new web3.eth.Contract(depositABI, ethAddressConfig.deposit_Address);
      setSubmitLoading(true);
      setTransactionLoader(true);
      try {
        await depositABIObject.methods
          .exitPool(amount)
          .send({ from: accountId })
          .then((receipt) => {
            setTransactionLoader(false);
            if (receipt.status) {
              toast.success('Pool Exit Successfully');
              setSubmitLoading(false);
              getAllUnlockedBalance();
            } else {
              setSubmitLoading(false);
              toast.error('Transaction Failed');
            }
          });
      } catch (err) {
        setTransactionLoader(false);
        setSubmitLoading(false);
        toast.error(err.message);
      }
    } else {
      setSubmitLoading(false);
    }
  };

  return {
    state,
    actions: { redeemMGTTokensHandler, redeemMYTTokensHandler, exitPoolHandler },
  };
});

export default useReward;
