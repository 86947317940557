import axios from 'axios';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import './styles/global.scss';
import 'tailwindcss/tailwind.css';
import { ToastContainer } from 'react-toastify';
import { composeComponents } from 'utils/extra/component';
import Header from 'organisms/Header';
import { AuthGate } from 'RoutesSettings/AuthGate';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { AuthProvider } from 'contexts/AuthenticationContext';
import 'react-toastify/dist/ReactToastify.css';
import SideNavbar from 'molecules/SideNavbar';
import { BalanceProvider } from 'contexts/BalanceContext';
import { RewardProvider } from 'contexts/RewardsContext';
import { FaucetProvider } from 'contexts/FaucetContext';
import { DepositProvider } from 'contexts/DepositContext';
import { StakingProvider } from 'contexts/StakingContext';
import { TasklistProvider } from 'contexts/TasklistContext';
import { BugReportProvider } from 'contexts/BugReportContext';
import LandingPage from 'modules/LandingPage';
import { useEffect } from 'react';
import { ScrollToTop } from 'utils/scrollToTop';

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

function App() {
  const Providers = composeComponents(
    AuthProvider,
    BalanceProvider,
    RewardProvider,
    FaucetProvider,
    DepositProvider,
    StakingProvider,
    TasklistProvider,
    BugReportProvider,
  );
  useEffect(() => {
    AOS.init({
      duration: 2000,
      disable: 'mobile',
    });
  }, []);
  return (
    <div className="App  h-full bg-cover bg-no-repeat">
      <Providers>
        <ToastContainer hideProgressBar autoClose={2000} limit={1} />

        <Router>
          <ScrollToTop />
          <Switch>
            {' '}
            <Route
              exact
              path="/app"
              render={(props) => (
                <div className="bg-bgImage bg-cover">
                  <Header />
                  <div className="grid md:grid-cols-10 min-h-screen mx-auto pt-32 px-10 py-2 max-w-7xl">
                    <div className="md:col-span-3 hidden md:block px-5 sm:px-0">
                      <SideNavbar />
                    </div>
                    <div className="mt-5 px-5 sm:px-0 md:ml-10 md:col-span-7 relative">
                      <AuthGate />
                    </div>
                  </div>{' '}
                </div>
              )}
            />
            <Route path="/" component={LandingPage} />
          </Switch>
        </Router>
      </Providers>
    </div>
  );
}

export default App;
