import React from 'react';

import { Button } from 'molecules/Button';
import ReactPlayer from 'react-player';
import useVisibleState from 'utils/Hooks/useVisibleStates';
import ProtocolSectionModal from './components/ProtocolSectionModal';

export default function ProtocolSection() {
  const { visible, hide, show } = useVisibleState(false);

  const videoUrl = `https://www.youtube.com/watch?v=2tlfYvkK7gA`;

  return (
    <>
      <div className="h-full  0  pt-20 md:pt-32 " data-aos="fade-up">
        <div className="font-bold text-3xl md:text-5xl" data-aos="fade-up">
          Protocol Functionality
        </div>
        <div className="flex justify-center items-center flex-col mx-auto  px-10 py-10 md:py-16 max-w-6xl  flex-col space-y-2 md:space-y-0">
          <div className="md:flex  w-full">
            <div className=" md:w-1/2">
              <div data-aos="fade-up-right">
                <div>
                  <div>
                    <div className="text-left font-bold text-gray-500 text-xl md:text-2xl">
                      Protocol Functionality{' '}
                    </div>
                    <div className="mt-2 md:mt-5 text-left font-thin text-gray-800">
                      Here You Can Find Out How The Protocol Works And Also Get An Insight Into The
                      Escrow Process
                    </div>
                  </div>
                </div>

                <div className="w-40 mt-5 md:mt-8">
                  <Button
                    className="shadow-corner border-4  px-3 py-2 w-full"
                    style={{
                      backgroundImage: 'linear-gradient(150deg, #3B63FF 0%, #AD29F2 100%)',
                      boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 50%)',
                    }}
                    onClick={show}
                  >
                    <span className="text-lg">Read More</span>
                  </Button>
                </div>
              </div>
            </div>
            <div
              className=" md:w-1/2 shadow-out  h-48 lg:h-80 mt-8 md:mt-0"
              data-aos="fade-up-left"
            >
              <ReactPlayer
                autoPlay
                url={videoUrl}
                width="100%"
                height="100%"
                borderRadius="8px"
                config={{
                  youtube: {
                    playerVars: { modestbranding: 1 },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ProtocolSectionModal open={visible} onClose={hide} />
    </>
  );
}
