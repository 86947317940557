import React, { useCallback } from 'react';
import Modal from 'molecules/ModalTemplate';

import SignInForm from './components/SignInForm';
import useAuth from 'contexts/AuthenticationContext';

const SigninModal = () => {
  const {
    actions: { setShowAuthModal, loginUser, sideTabHandler },
  } = useAuth();

  const closeModal = () => {
    setShowAuthModal('');
    sideTabHandler(0);
  };
  const onSubmit = useCallback(
    async (values) => {
      await loginUser(values);
    },
    [loginUser],
  );

  return (
    <Modal headerTextId="add.family.member" open={true} onClose={closeModal} headerText="SignIn">
      <div className=" md:w-1/2 mx-auto my-5">
        <SignInForm onSubmit={onSubmit} />
      </div>
    </Modal>
  );
};

export default SigninModal;
