import { useReducer } from 'react';
import { createContainer, createReducer } from 'utils/context';

import ethAddressConfig from 'utils/abiSection/address/ethAddressConfig';
import useAuth from './AuthenticationContext';
import { escrowABI } from 'utils/abiSection/abis';
import { toast } from 'react-toastify';
import TxnService from 'services/TxnService';

const initialState = {};

const tasklistReducer = createReducer({});

export const { useContext: useTasklist, Provider: TasklistProvider } = createContainer(() => {
  const [state] = useReducer(tasklistReducer, initialState);

  const {
    state: { accountId },
    actions: { getTxn, getTxnByStatus, setTransactionLoader, setSubmitLoading, getTaskList },
  } = useAuth();

  const updateTransaction = async (hash) => {
    await TxnService.updateTransaction(hash, 'unlock');
    await getTxn();
    await getTxnByStatus();
    await getTaskList();
  };

  const handleUnlockEvent = async (transaction, lockStatus) => {
    let senderId = transaction.lockAddress;
    let receiverId = transaction.unlockAddress;
    let hash = transaction.transactionHash;
    let lockId = transaction.lockId;
    let amount = transaction.amount;
    const web3 = window.web3;
    if (web3 !== undefined && web3.eth !== undefined) {
      const escrowABIObject = new web3.eth.Contract(escrowABI, ethAddressConfig.escrow_Address);

      const lockValueBN = web3.utils.toWei(amount.toString(), 'Ether');
      try {
        setSubmitLoading(true);
        await escrowABIObject.methods
          .unlockTokens(lockId, lockValueBN)
          .send({ from: accountId })
          .on('transactionHash', (hash) => {
            setTransactionLoader(true);
          })
          .on('receipt', (receipt) => {
            toast.success('Transaction Success');
            setSubmitLoading(false);
            setTransactionLoader(false);
            updateTransaction(hash, senderId, receiverId, lockStatus);
          });
      } catch (err) {
        setSubmitLoading(false);
        setTransactionLoader(false);
        toast.error(err?.message);
      }
    }
  };

  return {
    state,
    actions: {
      handleUnlockEvent,
    },
  };
});

export default useTasklist;
