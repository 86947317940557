import Moment from 'moment';

export const convertFromWei = (value) => {
  if (value) {
    return window.web3.utils.fromWei(value?.toString(), 'Ether');
  }
  return '';
};

export const convertToWei = (value) => {
  if (value) {
    return window?.web3?.utils?.toWei(value.toString(), 'Ether');
  }
  return '';
};

export const sideTabs = [
  {
    textid: 'Faucets',
    link: 0,
    visible: true,
    disabled: false,
    content: 'Dashboard',
  },
  {
    textid: 'deposit',
    link: 1,
    disabled: true,
    visible: true,
  },
  {
    textid: 'Staking',
    link: 2,
    visible: true,
    disabled: false,
  },
  {
    textid: 'Escrow',
    link: 3,
    visible: true,
    disabled: false,
    content: '',
  },
  {
    textid: 'Task List',
    link: 4,
    visible: true,
    disabled: false,
    content: '',
  },
  {
    textid: 'Transactions',
    link: 5,
    visible: true,
    disabled: false,
    content: '',
  },
  {
    textid: 'Report a Bug',
    link: 6,
    visible: true,
    disabled: false,
    content: '',
  },
];
export const convertDateTime = (date) => {
  let newdate = new Date(date);
  var seconds = Math.floor((new Date() - newdate) / 1000);
  let interval = seconds / 86400;
  if (interval > 1) {
    return Moment(date).format('MM/DD/YYYY - hh:mm:ss');
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' hours';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' minutes';
  }
  return Math.floor(seconds) + ' seconds';
};
