import React from 'react';
import useAuth from 'contexts/AuthenticationContext';
import { ClipLoader } from 'react-spinners';

export const TransactionLoader = ({ text }) => {
  const {
    state: { transactionLoading, isConnected },
  } = useAuth();

  return (
    <>
      {transactionLoading && isConnected && (
        <button className="fixed border border-primary bg-secondary rounded-md px-4 py-1 right-10 top-10 z-50">
          <div className="flex relative" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div className="mr-2 text-white text-sm">1 Pending</div>
            <div>
              <ClipLoader size={10} color="#fff" />
            </div>
          </div>
        </button>
      )}
    </>
  );
};
