import axiosService from 'utils/api/axios';

const LoginService = {
  login: function async(payload) {
    return axiosService
      .post('api/auth/login', payload)
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  signup: function async(payload) {
    return axiosService
      .post('api/v1/account', payload)
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  test: function async() {
    return axiosService
      .post(
        'api/auth/test',
        {},
        {
          'Access-Control-Allow-Credentials': true,
          withCredentials: true,
        },
      )
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  logout: function async() {
    return axiosService
      .get(
        'api/auth/logout',
        {},
        {
          'Access-Control-Allow-Credentials': true,
          withCredentials: true,
        },
      )
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

export default LoginService;
