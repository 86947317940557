import React from 'react';

import Modal from 'molecules/LandingModalTemplate';
import { CheckIcon } from '@heroicons/react/outline';
import { Image } from 'atoms/Image/Image';
import chart from 'assets/images/chart.jpg';
import chart2 from 'assets/images/chart2.jpeg';
import chart3 from 'assets/images/chart3.jpeg';

const OverViewSectionModal = ({ onClose, open }) => {
  return (
    <Modal open={open} onClose={onClose} headerText="Overview">
      <div className="mx-auto max-w-auto md:max-w-7xl  text-gray-500 text-lg">
        <div>
          <ul>
            <li className="flex mb-2">
              <span>
                <CheckIcon className="w-8 h-8 text-indigo-700 font-bold cursor-pointer" />
              </span>
              <span className="text-left font-thin text-gray-700 ml-2 text-lg">
                Debt-Ridden Football Clubs Are Staring at a Financial Crisis
              </span>
            </li>
            <li className="flex">
              <span>
                <CheckIcon className="w-8 h-8 text-indigo-700 font-bold cursor-pointer" />
              </span>
              <span className="text-left ml-2 font-thin text-gray-700 text-lg">
                Fans want to get involved in The Governance of Football Clubs
              </span>
            </li>
          </ul>
          <div className="text-left mt-5  ml-2 font-thin text-gray-700 text-lg">
            DFL Solves Both These Problems
          </div>
        </div>
        <div className="grid gap-5">
          <div className="text-gray-500 font-bold text-2xl  mt-10">
            The History of Football Clubs:
          </div>
          <div>
            Football clubs date back to the middle of the 19th century. Sheffield FC, England is
            credited as the first known official football club. It was established in 1857 as
            Sheffield Club. The Wanderers, Hallam, and Crystal Palace, all England-based clubs,
            followed suit in quick succession.
          </div>
          <div>
            The working class who were passionate about the game were the brains behind these clubs.
            They came together on weekends, organized local matches, has a couple of drinks, wagered
            on their favorite teams and players, and generally had a great time.
          </div>
          <div>
            The workers were passionate about their clubs, driven by an unshakable loyalty. They
            cheered and celebrated when their teams won and berated the players when they lost a
            game. The club became an inseparable part of their existence. The weekend meetings and
            camaraderie the club members enjoyed were something they looked forward to. They would
            never miss it for any reason.
          </div>
          <div>
            Today, football clubs operate as full-fledged corporate houses, some even at a more
            complex level than some of the biggest global corporates. And big corporates need big
            money to manage the show.
          </div>
          <div className="font-bold text-gray-500">Why Football Clubs Are Deep In The Red</div>
          <div>
            A football club’s revenue traditionally comes from the day’s match tickets, season
            ticket sales, catering, merchandising, sale of media rights, and sponsorship agreements.
            All these came to crashing naught because of the pandemic.
          </div>
          <div>
            Nearly all major football clubs spend a major part of their money on player transfer
            fees and player salaries. Both are not cheap, and that’s because there’s no cap on how
            much players earn. It is not uncommon for clubs, especially the major ones, to spend
            more than their earnings. The spending gets underwritten by future revenues as the
            television deals and sponsorship arrangements are multi-year contracts.
          </div>{' '}
          <div>
            The huge piles of debt stem from the extreme competition between the teams. There is a
            cutthroat competition to sign up the top players and be the best in their leagues. They
            are all fighting to win the biggest tournaments and trophies. To achieve all these, they
            keep hiking salaries and transfer fees of top players.
          </div>
          <div>
            Most football clubs, including many of those involved in Europe, have piled up massive
            debts totaling hundreds of millions of pounds. Nearly every football club in Europe, and
            they number around 400, are in debt and it looks unlikely they will come out of it in
            the near future unless they take some daring and dramatic steps.
          </div>
          <div>
            The debt stats are hazy because it is not easy to make a realistic assessment of the
            situation. The picture is further complicated by the fact that some clubs have used the
            money to invest in infrastructure while others have so-called “soft loan” arrangements
            with their owners.
          </div>
          <div>
            2020 turned out to be a calamitous year for the finances of nearly all financial clubs
            regardless of the leagues they were associated with.
          </div>
          <div>
            The unprecedented pandemic and its unforeseen extended global run forced these clubs to
            play behind closed doors and empty stadiums. They also had to pay vast sums as rebates
            to broadcasters for interrupted schedules.
          </div>
          <div>
            The football club debt list of the top ten clubs presents a scary picture that few would
            have predicted before 2020.
          </div>
          <div>
            Here is a comparison of the change in revenue of the top clubs for the year 2018-19 &
            2019-20:
          </div>
          <div className=" md:w-1/2 mx-auto ">
            <Image src={chart} alt="chart" />
          </div>
          <div className=" md:w-1/2 mx-auto ">
            <Image src={chart2} alt="chart" />
          </div>
          <div className="font-bold text-center text-2xl my-5">
            Source:{' '}
            <a
              href="https://scroll.in/field/866062/manchester-united-top-the-chart-in-revenue-generation-among-football-clubs"
              className="text-red-700"
              target="_blank"
              rel="noreferrer"
            >
              Click Here
            </a>
          </div>
          <div className="text-red-500 font-bold text-center text-2xl">
            “Revenue Earnings of the Top 20 Football Clubs 2019/20”
          </div>
          <div>
            The full impact of the disastrous circumstances in which the clubs find themselves is
            not yet entirely clear. But yes, they are staring down an endless barrel and need funds
            super urgently – and in super huge numbers!
          </div>
          <div className="font-bold">This is the current debt scenario of the top 10 clubs:</div>
          <div className=" md:w-1/2 mx-auto ">
            <Image src={chart3} alt="chart" />
          </div>
          <div className="text-red-500 font-bold text-center text-2xl">
            “The football club debt list of the top ten clubs presents a scary picture that few
            would have predicted before 2020.”{' '}
          </div>
          <div className="font-bold">A Brazen Attempt To Grab Control</div>
          <div>
            Meanwhile, in an interesting development, the world’s most powerful football clubs
            attempted to create and launch a brand new super league in the form of a closed-shop
            competition. In a move that fans saw as complete disregard for talent and skills, this
            all-new and powerful group also guaranteed a permanent spot for all founding members in
            the league, regardless of how they perform.
          </div>
          <div>
            This move caused a tremendous uproar among football fans. The owners were however
            desperate to get things going, given the fact that the show has come to a complete
            standstill, leaving no scope for any flow or generation of revenue.
          </div>
          <div>
            It was pretty clear that the huge loss of revenue and profit was giving the club owners
            and sponsors sleepless nights. Also, there is no light at the end of the tunnel which
            meant they had to make some desperate move to get things rolling again.
          </div>
          <div>
            The furious fans vowed to do everything to stop the club owners from doing something on
            the lines of the closed-shop competition again.
          </div>
          <div>
            This blatant attempt to take control of the sport that has the world’s largest
            fan-following backfired quickly as one club after another pulled out of the arrangement.
            The move fizzled out but it showed the level of desperation of club owners and also gave
            an insight into the depth of their financial crisis.
          </div>
          <div>
            Football fans want to have a change in the way football clubs are governed and that can
            happen when they get a chance to participate and have a say in its actual governance.
          </div>
          <div>
            Running a football club involves a lot of liquidity and it is not possible to raise such
            huge sums of cash or liquidity using conventional resources or methods.
          </div>
          <div className="font-bold">DeFi – Decentralized Finance</div>
          <div>That’s where Decentralized Finance or DeFi comes in.</div>
          <div>
            DeFi refers to an ecosystem of financial applications built on top of blockchain
            networks.
          </div>
          <div>
            DeFi is an open-source, completely transparent and permission-less, financial service
            ecosystem that’s available to everyone. And, it operates without any central authority.
          </div>
          <div>
            Interoperable DeFi applications on public blockchains have the potential to create a
            whole new ground-breaking system of financial markets, products, and services.
          </div>
          <div>
            This is just the kind of funding arrangement that the distressed and debt-ridden
            football clubs need to climb back from the deep hole they find themselves in today.
          </div>
          <div className="font-bold">DFL</div>
          <div>
            We play the critical role of intermediary, connecting Decentralized Finance to these
            distressed football clubs.
          </div>
          <div>
            By using our protocol we will ensure the supply of funding/liquidity to these clubs by
            using a process called Farming or Liquidity Mining – a time-tested and proved process in
            the cryptosphere.
          </div>
          <div>
            We will also introduce governance tokens, where we will split the entire power of the
            clubs into equity and governance tokens. These governance tokens will be used for
            liquidity mining and issued to liquidity providers. By doing this the problem of
            financial crunch faced by the football clubs can be solved and at the same time, fans
            will also have a say in the governance of the football club.
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OverViewSectionModal;
