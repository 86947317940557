import React from 'react';

export const InputField = ({ touched, error, disabled, validation, type, ...rest }) => {
  return (
    <>
      <input
        type={type}
        className={`appearance-none block w-full px-3 py-2 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-medium  ${
          (error && touched) || (validation && error) ? ' border-red-500 ' : ' border-gray-300'
        } ${disabled ? 'bg-gray-50' : ''} `}
        {...rest}
        disabled={disabled}
      />
    </>
  );
};
