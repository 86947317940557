import { useReducer, useCallback } from 'react';
import { createContainer, createReducer, createAction } from 'utils/context';
import BugReportService from 'services/BugReportService';
import { toast } from 'react-toastify';

const initialState = {
  topics: [],
  subTopics: [],
  bugReportList: [],
};

const actions = {
  setTopics: createAction('SET_TOPICS'),
  setSubTopics: createAction('SET_SUB_TOPICS'),
  setBugReportList: createAction('SET_BUG_REPORT_LIST'),
};

const bugReportReducer = createReducer({
  [actions.setTopics]: (state, { payload }) => ({
    ...state,
    topics: payload,
  }),
  [actions.setSubTopics]: (state, { payload }) => ({
    ...state,
    subTopics: payload,
  }),
  [actions.setBugReportList]: (state, { payload }) => ({
    ...state,
    bugReportList: payload,
  }),
});

export const { useContext: useBugReport, Provider: BugReportProvider } = createContainer(() => {
  const [state, dispatch] = useReducer(bugReportReducer, initialState);

  const getBugTopics = useCallback(async () => {
    try {
      const data = await BugReportService.getTopics();
      dispatch(actions.setTopics(data?.data?.data));
    } catch (err) {}
  }, []);

  const getBugSubTopics = useCallback(async (text) => {
    try {
      const data = await BugReportService.getSubTopics(text);
      dispatch(actions.setSubTopics(data?.data?.data));
    } catch (err) {}
  }, []);

  const handleBugReportForm = useCallback(async (values) => {
    try {
      await BugReportService.createBug(values);
      toast.success('Bug reported successfully');
    } catch (err) {}
  }, []);

  const getBugReportList = useCallback(async (pageNum) => {
    try {
      const data = await BugReportService.getBugReports(pageNum);
      dispatch(actions.setBugReportList(data?.data));
    } catch (err) {}
  }, []);

  return {
    state,
    actions: {
      getBugTopics,
      getBugSubTopics,
      handleBugReportForm,
      getBugReportList,
    },
  };
});

export default useBugReport;
