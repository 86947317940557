import React, { useCallback } from 'react';
import Modal from 'molecules/ModalTemplate';
import ExitPoolForm from './ExitPoolForm';
import useAuth from 'contexts/AuthenticationContext';
import useBalance from 'contexts/BalanceContext';
import { convertFromWei } from 'utils/utility';

const ExitPoolModal = ({ onClose, open, handleConfirm }) => {
  const onSubmit = useCallback(
    async (values) => {
      await handleConfirm(values?.amount);
    },
    [handleConfirm],
  );
  const {
    state: { user },
  } = useAuth();
  const {
    state: { MCTBalance },
  } = useBalance();
  return (
    <Modal
      headerTextId="add.family.member"
      open={open}
      // @ts-ignore
      onClose={onClose}
      headerText={`Are you sure ${user?.avatar?.toUpperCase()} ! You want to exit pool?`}
    >
      <div className=" md:w-1/2 mx-auto ">
        <div className="mt-3">
          <ExitPoolForm
            onSubmit={onSubmit}
            lockedBalance={convertFromWei(MCTBalance?.lockedMCT) || 0}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ExitPoolModal;
