import React, { useEffect } from 'react';

import ReactModal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    width: 'max-content',
    maxWidth: '100vw',
    borderRadius: '10px',
    border: 'none',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    background: 'rgb(25 31 41 / 51%)',
    zIndex: 100,
  },
};



export const Modal = ({ isOpen, onClose, ...rest }) => {
  const body = document.querySelector("body");

useEffect(()=>{
  if (isOpen) {
    // Disable scroll
    body.style.overflow = "hidden";
} else {
    // Enable scroll
    body.style.overflow = "scroll";
}
},[isOpen,body])

 return <ReactModal ariaHideApp={false} isOpen={isOpen}onRequestClose={onClose}  style={customStyles} {...rest} />
}
