import React, { useState } from 'react';
import LoginService from 'services/LoginService';
import Modal from 'molecules/ModalTemplate';
import { Button } from 'molecules/Button';
import useAuth from '../../contexts/AuthenticationContext';
import { toast } from 'react-toastify';

function CustomizedLogin(props) {
  const [isLogin, setIsLogin] = useState(false);
  const [username, setUserName] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const {
    state: { submitLoading, accountId },
    actions: { toggleLoginWalletPopup, setUser, setSubmitLoading },
  } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    var payload = {
      username: username,
      avatar: username,

      accountId: accountId,
    };
    setSubmitLoading(true);
    if (isLogin === true) {
      LoginService.login(payload)
        .then(function (response) {
          if (response.status === 200) {
            if (response.data && response.data.msg === 'New user. Please Signup') {
              setErrorMessage('You are new here. Please Signup');
              setIsLogin(false);

              setSubmitLoading(false);
            } else if (response.data && response.data.msg === 'Login Successful') {
              setUser(response.data.payload?.user);
              setErrorMessage('');
              setSubmitLoading(false);
              toggleLoginWalletPopup('close');
            }
          } else if (response.data.code === 204) {
            alert('username password do not match');
          } else {
            setErrorMessage('username does not exist');
            alert('Username does not exist');
          }
          setSubmitLoading(false);
        })
        .catch(function (error) {
          setSubmitLoading(false);
        });
    } else {
      LoginService.signup(payload)
        .then(async (response) => {
          setSubmitLoading(true);
          if (response.status === 201) {
            if (response.data) {
              setUser(response.data.data);
              setIsLogin(true);
              setSubmitLoading(false);
              toggleLoginWalletPopup('close');
            }
          } else if (response.status === 409) {
            toast.error(response?.data?.message);
            setErrorMessage(response?.data?.message);

            setSubmitLoading(false);
          }
        })
        .catch(function (error) {
          setSubmitLoading(false);
        });
    }
  };

  const usernameChange = (e) => {
    setUserName(e.target.value);
  };

  return (
    <Modal
      headerTextId="add.family.member"
      open={true}
      // @ts-ignore

      headerText="Choose an avtar name for yourself."
    >
      <div classNameName="rounded-lg">
        <div className="text-sm font-medium ">
          - Characters length between 4-15 <br />
          - Can Contain numbers.
          <br />- Can Contain letters.
          <br />
          - No special characters allowed
          <br />
        </div>

        <div className="my-10 flex items-center flex-col">
          <div className="mt-2 mb-1 text-base font-medium text-left text-white">
            Enter your name
          </div>

          <div className="text-center w-1/2">
            <input
              type="text"
              value={username}
              required=""
              onChange={usernameChange}
              className="appearance-none border-gray-300 block w-full text-black px-3 py-2 border-2 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-medium "
            />

            {errorMessage !== '' && (
              <span className="text-red-500 font-medium text-sm bg-secondary p-1">
                {errorMessage}
              </span>
            )}
          </div>
        </div>
        <div className="flex justify-center  ">
          <Button loading={submitLoading} type="button" className="mt-1 w-50" onClick={handleLogin}>
            Done
          </Button>
        </div>
        <div className="text-center  mt-10">
          <div className="text-white text-base font-bold mt-5">
            * note: You will not be allowed to change the avatar name later.
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default CustomizedLogin;
