import axiosService from 'utils/api/axios';
const TxnService = {
  fetchTransaction: function async(text, pageNum = 1) {
    return axiosService
      .get('api/v1/transactions/' + text + '?offset=' + (Number(pageNum) - 1) * 5 + '&limit=5')
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  fetchTransactionByStatus: function async(text, pageNum = 1) {
    return axiosService
      .get(
        'api/v1/transactions/status/' + text + '?offset=' + (Number(pageNum) - 1) * 5 + '&limit=5',
      )
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  fetchTransactionTaskList: function async(text, pageNum = 1) {
    return axiosService
      .get(
        'api/v1/transactions/tasklist/' +
          text +
          '?offset=' +
          (Number(pageNum) - 1) * 5 +
          '&limit=5',
      )
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  updateTransaction: function async(transactionId, lockStatus) {
    return axiosService
      .patch('api/v1/transactions/' + transactionId, {
        lockStatus: lockStatus,
      })
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  createTransaction: function async(payload) {
    return axiosService
      .post('api/v1/transactions/', payload)
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

export default TxnService;
