import axiosService from 'utils/api/axios';
import { hydrate } from 'utils/persist';
const BugReportService = {
  getTopics: function async(payload) {
    return axiosService
      .get('api/v1/topics', payload)
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getSubTopics: function async(payload) {
    return axiosService
      .get(`api/v1/topics/${payload}`)
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },
 
  createBug: function async(payload) {
    const user = hydrate('LOGIN_USER', localStorage);
    const customHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.tokenData}`
    }
    return axiosService
      .post('api/v1/bug-reports', payload,customHeaders)
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getBugReports: function async(pageNum) {
    const user = hydrate('LOGIN_USER', localStorage);
    const customHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.tokenData}`
    }
    return axiosService
      .get('api/v1/admin/bug-reports?offset=' + (Number(pageNum) - 1) * 5 + '&limit=10', customHeaders)
      .then((resolve) => {
        return resolve;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

export default BugReportService;
