import React from 'react';
import ReactPlayer from 'react-player';
import Modal from 'molecules/LandingModalTemplate';

const ProtocolSectionModal = ({ onClose, open }) => {
  const videoUrl = `https://www.youtube.com/watch?v=2tlfYvkK7gA`;

  return (
    <Modal open={open} onClose={onClose} headerText="Protocol Functionality">
      <div className="mx-auto max-w-auto md:max-w-7xl  text-gray-500 text-lg">
        <div>
          <div className=" md:w-1/2 shadow-out mx-auto mb-16 h-48 lg:h-80 mt-8 md:mt-0">
            <ReactPlayer
              autoPlay
              url={videoUrl}
              width="100%"
              height="100%"
              borderRadius="8px"
              config={{
                youtube: {
                  playerVars: { modestbranding: 1 },
                },
              }}
            />
          </div>
        </div>
        <div className="grid gap-5 mt-5">
          <p>
            <strong>Structure One</strong>
          </p>
          <p>
            The DFL website comes with an app that is the gateway of the user into the protocol.
          </p>
          <p>When a user clicks on the app, they get access to the protocol.</p>
          <p>
            When the user enters the protocol, they will have access to the open liquidity pool
            where anyone can deposit money.
          </p>
          <p>
            Money is deposited from the wallet into the protocol in the form of stablecoins such as
            USDT, USDC, and DAI. A deposit button is made available for this purpose.
          </p>
          <p>
            When the funders come in with a particular amount to be placed in the deposit box, they
            are also given an option of how much percentage of their total fund they want to
            allocate for the football club.
          </p>
          <p>For instance:</p>
          <p>
            A funder with a million to put in can choose to allocate 70 percent of that sum to the
            club.
          </p>
          <p>The balance of 30 percent of their money goes to the protocol.</p>
          <p>
            The 700K invested in the club gains value in the form of the equity of the football club
            and the tokens. This amount will be locked and will be used for the purchase of the
            football club along with money gained through a similar process from other funders.
          </p>
          <p>
            The 300K unlocked amount or any part of this fund can be withdrawn back by the funder
            anytime they want after using the Escrow.
          </p>
          <p>
            <strong>What Are Yield Tokens?</strong>
          </p>
          <p>
            For the amount locked, the liquidity provider will get ‘Yield Tokens’ as fees. The
            process of issuing yield tokens will happen continuously, adding more value to the
            locked liquidity every day. This process of ‘Yield Farming’ will continue until such
            time when the deposited money is used by the protocol for purchasing the football club.
            That’s how the pool structure works.
          </p>
          <p>
            Assuming that 50 million is sitting in the pool and the protocol has not used that money
            or any part of it to buy a football club, the funders will get rewards continuously in
            the form of yield tokens which will keep adding more value to their initial liquidity .
          </p>
          <p>
            The buying of a football is a complex process as there are multiple stages and processes
            involved. So as long as the money remains idle in the pool, the funders will
            continuously get yield tokens.
          </p>
          <p>
            <strong>Governance Tokens Through LP Liquidity Pools And Staking Contract:</strong>
          </p>
          <p>
            Funders can place the fees or the yield tokens in LP pools and add liquidity and get
            governance tokens of the protocol as rewards.&nbsp;
          </p>
          <p>
            Additional governance tokens will also be given as rewards as an incentive for long-term
            staking of DFL governance tokens.
          </p>
          <p>
            <strong>Structure Two</strong>
          </p>
          <p>
            This structure is designed for small players or fans who are providing little equity in
            the protocol but want to follow their passion for the game through betting.
          </p>
          <p>Example</p>
          <p>A and B are two fans betting on a match.</p>
          <p>Both their bid amounts are placed and locked in an Escrow account.</p>
          <p>
            If B is authorized to operate the escrow account and if he wins the bet, he will unlock
            the Escrow and take the money.
          </p>
          <p>If A wins the bet, B will transfer the money to A from the escrow account.</p>
          <p>
            Alternately, A and B can appoint a third party C to operate the Escrow. The role of C is
            purely that of a middleman. C will not have access to the fund in the Escrow. C is only
            authorized to unlock the Escrow and release the amount to the winner.
          </p>
          <p>
            The protocol will record and save all the transaction history and the task list on the
            system for ready reference.
          </p>
          <p>
            The charge for using escrow comes in the form of a small amount deducted as tax or fees
            from the yield tokens.
          </p>
          <p>
            Those who place their money in the Escrow and keep completing transactions are allocated
            governance tokens as an incentive. This is an option created specifically for those who
            are excited about the protocol concept but do not have the big funds to spend.
          </p>
          <p>
            It helps create a large community because fans with limited financial resources can
            bring in their friends.
          </p>
          <p>
            They can be part of the protocol which gives them the opportunities to earn governance
            tokens as incentives.
          </p>
          <p>Escrow can be used by anyone in the protocol for the purpose they deem fit.</p>
        </div>
      </div>
    </Modal>
  );
};

export default ProtocolSectionModal;
