import React, { useEffect } from 'react';

import LandingPageHeader from './components/LandingPageHeader';
import { LandingPageFooter } from './components/LandingPageFooter';
import LandingPageBanner from './components/LandingPageBanner';
import OverViewSection from './components/OverviewSection';
import ProcessSection from './components/ProcessSection';
import ComponentsSection from './components/ComponentsSection';
import ProtocolSection from './components/ProtocolSection';
import ChartSection from './components/ChartSection';

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div>
      <LandingPageHeader />

      <div>
        {' '}
        <LandingPageBanner />
      </div>
      <div className="relative ">
        <div className="bg-landingBgRight bg-cover bg-no-repeat  bg-right-bottom absolute w-full h-full opacity-90  "></div>

        <div className="relative bg-landingBgLeft  bg-no-repeat   opacity-90 ">
          <div id="1">
            {' '}
            <OverViewSection />
          </div>
          <div id="2">
            <ProcessSection />
          </div>
          <div id="3">
            <ComponentsSection />
          </div>
          <div id="4">
            {' '}
            <ProtocolSection />
          </div>
        </div>
      </div>
      <div>
        <div id="5">
          <ChartSection />
        </div>
        <LandingPageFooter />
      </div>
    </div>
  );
};

export default LandingPage;
