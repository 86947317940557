import React from 'react';
import { useField } from 'formik';
import { InputField } from 'atoms/InputField';
import { Text } from 'molecules/Text';
import { FormikError } from 'atoms/Error';
import InputTextArea from 'atoms/InputTextArea';

export const FormikInput = ({ ...props }) => {
  const [field, meta] = useField(props.name);
  const { touched, error } = { ...meta };
  return (
    <div className="w-full">
      {props.label && (
        <label htmlFor={props.name} className="block text-sm font-medium text-white mb-2">
          <Text>{props.label}</Text>
        </label>
      )}
      <div className="mt-1 relative">
        <InputField
          touched={touched}
          error={error}
          {...field}
          {...props}
          validation={props.validation}
        />
      </div>

      <FormikError name={props?.name} />
    </div>
  );
};

export const FormikTextArea = ({ ...props }) => {
  const [field, meta] = useField(props.name);
  const { touched, error } = { ...meta };
  return (
    <div className="w-full">
      {props.label && (
        <label htmlFor={props.name} className="block text-sm font-medium text-primary mb-2">
          <Text>{props.label}</Text>
        </label>
      )}
      <div className="mt-1 relative">
        <InputTextArea
          touched={touched}
          error={error}
          {...field}
          {...props}
          validation={props.validation}
        />
      </div>

      <FormikError name={props?.name} />
    </div>
  );
};
