import React, { useCallback } from 'react';
import Modal from 'molecules/ModalTemplate';

import SignUpForm from './components/SignUpForm';
import useAuth from 'contexts/AuthenticationContext';

const SignupModal = () => {
  const {
    actions: { setShowAuthModal, Signup },
  } = useAuth();

  const closeModal = () => {
    setShowAuthModal('');
  };

  const onSubmit = useCallback(
    async (values) => {
      await Signup(values);
    },
    [Signup],
  );
  return (
    <Modal headerTextId="add.family.member" open={true} onClose={closeModal} headerText="Sign Up">
      <div className=" md:w-1/2 mx-auto my-5">
        <SignUpForm onSubmit={onSubmit} />
      </div>
    </Modal>
  );
};

export default SignupModal;
