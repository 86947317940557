import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import { Text } from 'molecules/Text';
import { Modal } from 'atoms/Modal';

const ModalTemplate = ({ onClose, open, headerText, children }) => {
  return (
    <>
      <Modal isOpen={open} onClose={onClose} className="bg-bgImage p-5 absolute rounded-md">
        {/* for above sm screen */}
        <div className="hidden sm:block" style={{ width: '40vw' }}>
          <div className="flex justify-between border-b mb-4 pb-2 px-0">
            <Text className="font-bold text-white">{headerText}</Text>
            {onClose && (
              <XIcon
                className="w-6 h-6 text-secondary font-bold cursor-pointer"
                onClick={onClose}
              />
            )}{' '}
          </div>

          <div className="mt-6">{children}</div>
        </div>

        {/* for below sm screen */}
        <div className="w-72 sm:hidden">
          <div className="flex justify-between border-b mb-4 pb-2 px-0">
            <Text className="font-medium text-white">{headerText}</Text>
            <XIcon className="w-6 h-6 text-secondary font-bold cursor-pointer" onClick={onClose} />
          </div>

          <div className="mt-6">{children}</div>
        </div>
      </Modal>
    </>
  );
};

export default ModalTemplate;
