import React, { useEffect } from 'react';
import { UnauthenticatedRoutes, AuthenticatedRoutes } from './Routes';
import useAuth from '../contexts/AuthenticationContext';
import MyRewards from 'modules/MyRewards';
import useBalance from 'contexts/BalanceContext';
import Loader from 'atoms/Loader';
import CustomizedLogin from 'modules/CustomisedLogin';
import SigninModal from 'modules/SigninModal';
import SignupModal from 'modules/SignupModal';

export const AuthGate = () => {
  const {
    state: {
      isConnected,
      accountId,
      authenticating,
      user,
      sideTab,
      isLoginWalletOpen,
      showAuthModal,
      isLoggedIn,
    },
    actions: { getAccountDetails, getTxn, getTaskList, getTxnByStatus },
  } = useAuth();

  const {
    actions: { getAllUnlockedBalance },
  } = useBalance();

  useEffect(() => {
    if (accountId) {
      getAccountDetails(accountId);
    } // eslint-disable-next-line
  }, [accountId]);

  useEffect(() => {
    if (user) {
      getAllUnlockedBalance();
      getTxn();
      getTxnByStatus();
      getTaskList();
    }
    // eslint-disable-next-line
  }, [user]);

  if (authenticating) {
    return <Loader />;
  }

  if (isConnected && isLoginWalletOpen) {
    return <CustomizedLogin />;
  }

  if (isConnected) {
    return (
      <div>
        {sideTab !== 0 && sideTab !== 5 && sideTab !== 6 && <MyRewards />}
        <AuthenticatedRoutes />
        {!isLoggedIn && sideTab === 6 && <SigninModal />}
        {showAuthModal === 'signin' && <SigninModal />}
        {showAuthModal === 'signup' && <SignupModal />}
      </div>
    );
  }
  return (
    <>
      <div>
        <UnauthenticatedRoutes />
        {showAuthModal === 'signin' && <SigninModal />}
        {showAuthModal === 'signup' && <SignupModal />}
      </div>
    </>
  );
};
