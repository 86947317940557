import React from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { FormikInput } from '../../../molecules/FormikInput/FormikInput';
import { useFormSubmitWithLoading } from '../../../utils/Hooks/useFormSubmitWithLoading';
import { Button } from 'molecules/Button';

import {
  validateRequiredPassword,
  validateRequiredFirstName,
  validateRequiredLastName,
  validateRequiredEmail,
} from 'utils/validators';
import useAuth from 'contexts/AuthenticationContext';

const loginValidationSchema = yup.object().shape({
  firstName: validateRequiredFirstName().required('Required'),
  lastName: validateRequiredLastName().required('Required'),
  email: validateRequiredEmail(),

  password: validateRequiredPassword(),
  confirmPassword: yup
    .string()
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], 'Both passwords need to be the same'),
    })
    .required('Required'),
});

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

const SignUpForm = ({ onSubmit }) => {
  const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmit);
  const {
    actions: { setShowAuthModal },
  } = useAuth();
  return (
    <>
      <Formik
        initialValues={defaultValues}
        onSubmit={onSubmitHandler}
        validationSchema={loginValidationSchema}
      >
        {() => (
          <Form className="grid gap-2">
            <div className="grid gap-5 grid-cols-2  ">
              <FormikInput label="First Name" name="firstName" />
              <FormikInput label="Last Name" name="lastName" />
            </div>
            <FormikInput label="Email" name="email" />
            <FormikInput label="Password" name="password" type="password" />
            <FormikInput label="Confirm Password" name="confirmPassword" type="password" />
            <Button type="submit" className="mt-2" loading={loading}>
              Sign Up
            </Button>
            <div className="my-1 border"></div>

            <Button
              type="button"
              className="w-full border-primary border bg-lightBlue text-white"
              onClick={() => setShowAuthModal('signin')}
            >
              Already Have an Account
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SignUpForm;
