import React from 'react';
import ReactPlayer from 'react-player';
import Modal from 'molecules/LandingModalTemplate';
import { CheckIcon } from '@heroicons/react/outline';

const ProcessSectionModal = ({ onClose, open }) => {
  const videoUrl = `https://www.youtube.com/watch?v=VGoRFpDhIVw`;

  return (
    <Modal open={open} onClose={onClose} headerText="Initial Process">
      <div className="mx-auto max-w-auto md:max-w-7xl  text-gray-500 text-lg">
        <div>
          <div className=" md:w-1/2 shadow-out mx-auto mb-16 h-48 lg:h-80 mt-8 md:mt-0">
            <ReactPlayer
              autoPlay
              url={videoUrl}
              width="100%"
              height="100%"
              borderRadius="8px"
              config={{
                youtube: {
                  playerVars: { modestbranding: 1 },
                },
              }}
            />
          </div>
          <ul>
            <li className="flex mb-2">
              <span>
                <CheckIcon className="w-8 h-8 text-indigo-700 font-bold cursor-pointer" />
              </span>
              <span className="text-left font-thin text-gray-700 ml-2 text-lg">
                Creating a perfect Example For a De-centrally Run Football Club{' '}
              </span>
            </li>
            <li className="flex">
              <span>
                <CheckIcon className="w-8 h-8 text-indigo-700 font-bold cursor-pointer" />
              </span>
              <span className="text-left ml-2 font-thin text-gray-700 text-lg">
                Connecting Football Clubs to Liquidity Providers to Create The Perfect Decentralized
                Funding Structure{' '}
              </span>
            </li>
          </ul>
        </div>
        <div className="grid gap-5 mt-5">
          <p>
            <strong>How It Works:</strong>
          </p>
          <p>
            The mission of the protocol is to help financially distressed football clubs overcome
            their debt situation by providing access to liquidity.
          </p>
          <p>
            To create a perfect example of how a football club has to be decentralized, the
            community of DFL protocol will buy a football club.
          </p>
          <p>
            For this purpose, we will create an open funding pool in which any liquidity provider
            from any corner of the world can participate and contribute based on their funding
            capability. There are no curbs or limitations on the amount of funding. Neither are
            there any upper or lower limits of participation.
          </p>
          <p>
            Everyone who becomes a part of the funding pool can be a part of the decentralized
            football ownership system.
          </p>
          <p>
            The goal is to create a benchmark on how to decentralize a football club. This will be
            the working model for every other football club, looking for a similar arrangement to
            follow.
          </p>
          <ul className="list-disc ml-20">
            <li>
              We are not in the process of purchasing football clubs. Our goal is to create a
              benchmark for football club by creating a fully decentralized governance system.
            </li>
            <li>
              Once we have a decentralized system in place, the model can be visited, viewed,
              understood, and utilized by other football clubs looking for a similar solution to
              come out of their debt situation.
            </li>
            <li>
              The benchmark that we have created can be used as a reference point, a case study for
              others to follow suit.
            </li>
          </ul>
          <p>
            <strong>Provide Liquidity In Real Assets With Great Potential Value</strong>
          </p>
          <p>
            Liquidity providers can get a genuine opportunity to deal in real assets. Most
            importantly, the element of risk in providing liquidity is very low – almost negligible.
          </p>
          <p>
            We offer everyone investor the opportunity to be a part of the ownership of their
            favorite football club by opening the pool for all investors at the same time.
          </p>
          <p>
            Regardless of whether you are a small-time investor looking to do your bit for saving
            your football club from sinking into further debts or whether you are an established
            Venture Capitalist, you can all be part of governing real, valuable asset.
          </p>
          <p>Remember, the pool will be open for liquidity funding just for one time.</p>
          <p>Once we achieve the targeted funding, the pool will be closed permanently.</p>
          <p>This is a ONE-TIME process and will NOT be repeated again.</p>
          <p>
            We only want to be the intermediary between the football club and the liquidity
            providers.
          </p>
          <p>
            Club owners wanting to know how the whole thing works can simply check out our benchmark
            model where we will provide answers to everything you want to know about creating a
            completely decentralized football club management system.
          </p>
          <p>
            &nbsp;Club owners can also understand how this unique model is structured and how a
            decentralized club looks like from the inside.
          </p>
          <p>
            They can also learn everything about the structure of governance tokens as well as the
            equity structure of this new platform.
          </p>
          <p>
            We have to make sure that in this decentralized football club everything is structured
            in such a way that every liquidity provider – small or big – benefits from it. Also,
            every fan will feel proud to be part of the governance of their football club. Thus,
            they will be part of the ownership structure of the club they adore and respect.
          </p>
        </div>
      </div>{' '}
    </Modal>
  );
};

export default ProcessSectionModal;
