import React from 'react';
import ReactPlayer from 'react-player';

export default function ChartSection() {
  const videoUrl = `https://www.youtube.com/watch?v=W160TNkQ8iA`;
  return (
    <div className="h-full bg-chartSectionBg bg-cover bg-center md:bg-top py-2 pt-20 md:pt-32 ">
      <div className="font-bold text-3xl md:text-5xl text-white" data-aos="fade-right">
        Flow Chart
      </div>

      <div className="flex justify-center items-center flex-col mx-auto py-10 max-w-6xl  flex-col space-y-2 md:space-y-0">
        <div className="flex  h-48 md:w-1/2 lg:h-80 items-center " data-aos="fade-left">
          <ReactPlayer
            autoPlay
            url={videoUrl}
            width="100%"
            height="100%"
            borderRadius="8px"
            config={{
              youtube: {
                playerVars: { modestbranding: 1 },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
