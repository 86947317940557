import React from 'react';
import { Image } from 'atoms/Image/Image';
import chart from 'assets/images/chart.jpg';

import { CheckIcon } from '@heroicons/react/solid';
import { Button } from 'molecules/Button';
import OverViewSectionModal from './components/OverViewSectionModal';
import useVisibleState from 'utils/Hooks/useVisibleStates';

export default function OverViewSection() {
  const { visible, hide, show } = useVisibleState(false);

  return (
    <>
      <div
        className="h-full  0 pt-20 md:pt-32 "
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="font-bold text-3xl md:text-5xl" data-aos="fade-up">
          Overview
        </div>
        <div className="flex justify-center items-center flex-col mx-auto  px-10 py-10 md:py-16 max-w-6xl  flex-col space-y-2 md:space-y-0">
          <div className="md:flex items-center">
            <div className="text-left md:w-1/2 text-white text-lg font-bold">
              <Image src={chart} alt="chart" data-aos="fade-up-right" />
            </div>
            <div className="md:pl-10 md:w-1/2 mt-5 md:mt-0">
              <div data-aos="fade-up-left">
                <div>
                  <div>
                    <ul>
                      <li className="flex mb-2">
                        <span>
                          <CheckIcon className="w-8 h-8 text-indigo-700 font-bold cursor-pointer" />
                        </span>
                        <span className="text-left font-thin text-gray-700 ml-2 text-lg">
                          Debt-Ridden Football Clubs Are Staring at a Financial Crisis
                        </span>
                      </li>
                      <li className="flex">
                        <span>
                          <CheckIcon className="w-8 h-8 text-indigo-700 font-bold cursor-pointer" />
                        </span>
                        <span className="text-left font-regular ml-2 font-thin text-gray-700 text-lg">
                          Fans want to get involved in The Governance of Football Clubs
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="text-left mt-5 font-regular ml-2 font-thin text-gray-700 text-lg">
                  DFL Solves Both These Problems
                </div>{' '}
                <div className="w-40 mt-5 md:mt-8">
                  <Button
                    className="shadow-corner border-4  px-3 py-2 w-full"
                    style={{
                      backgroundImage: 'linear-gradient(150deg, #3B63FF 0%, #AD29F2 100%)',
                      boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 50%)',
                    }}
                    onClick={show}
                  >
                    <span className="text-lg">Read More</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OverViewSectionModal open={visible} onClose={hide} />
    </>
  );
}
