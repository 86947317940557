import { useReducer, useCallback } from 'react';
import { createContainer, createReducer, createAction } from 'utils/context';

import ethAddressConfig from 'utils/abiSection/address/ethAddressConfig';
import useAuth from './AuthenticationContext';
import { faucetsABI, USDCABI, USDTABI, daiTokenABI } from 'utils/abiSection/abis';
import { toast } from 'react-toastify';
import { convertFromWei } from 'utils/utility';

const initialState = {
  faucetStableCoinBalance: {},
};

const actions = {
  setFaucetStableCoinBalance: createAction('SET_FAUCET_STABLE_COIN_BALANCE'),
};

const faucetReducer = createReducer({
  [actions.setFaucetStableCoinBalance]: (state, { payload }) => {
    return {
      ...state,
      faucetStableCoinBalance: payload,
    };
  },
});

export const { useContext: useFaucet, Provider: FaucetProvider } = createContainer(() => {
  const [state, dispatch] = useReducer(faucetReducer, initialState);

  const {
    state: { accountId },
    actions: { setTransactionLoader },
  } = useAuth();

  const getFaucetStableCoinBalances = useCallback(async () => {
    const web3 = window.web3;
    if (web3 !== undefined && web3.eth !== undefined) {
      const daiTokenABIObject = new web3.eth.Contract(daiTokenABI, ethAddressConfig.dai_token);
      const USDTABIObject = new web3.eth.Contract(USDTABI, ethAddressConfig.USDT_Address);
      const USDCABIObject = new web3.eth.Contract(USDCABI, ethAddressConfig.USDC_Address);
      const DAIBalance = await daiTokenABIObject.methods
        .balanceOf(ethAddressConfig.FAUCETS_ADDRESS)
        .call();
      const USDTBalance = await USDTABIObject.methods
        .balanceOf(ethAddressConfig.FAUCETS_ADDRESS)
        .call();
      const USDCBalance = await USDCABIObject.methods
        .balanceOf(ethAddressConfig.FAUCETS_ADDRESS)
        .call();
      dispatch(
        actions.setFaucetStableCoinBalance({
          DAIBalance: convertFromWei(DAIBalance),
          USDTBalance: convertFromWei(USDTBalance),
          USDCBalance: convertFromWei(USDCBalance),
        }),
      );
    }
  }, []);

  const handleFaucetsForm = useCallback(
    async (amount, unitSelectedVal, setLoading) => {
      const web3 = window.web3;
      if (web3 !== undefined && web3.eth !== undefined) {
        const lockValueBN = web3.utils.toWei(amount.toString(), 'Ether');
        const faucetsABIObject = new web3.eth.Contract(
          faucetsABI,
          ethAddressConfig.FAUCETS_ADDRESS,
        );

        try {
          setLoading(true);
          setTransactionLoader(true);
          await faucetsABIObject.methods
            .mint(Number(unitSelectedVal), lockValueBN, accountId)
            .send({ from: accountId })
            .then(async (receipt) => {
              if (receipt.status) {
                toast.success('Transaction Success');
                setLoading(false);
                setTransactionLoader(false);

                await getFaucetStableCoinBalances();
              }
            })
            .catch((err) => {
              setLoading(false);
              setTransactionLoader(false);
              toast.error('Transaction Failed');
            });
        } catch (err) {
          setLoading(false);
          setTransactionLoader(false);
          toast.error(err.message);
        }
      }
    },
    [accountId, getFaucetStableCoinBalances, setTransactionLoader],
  );

  return {
    state,
    actions: {
      handleFaucetsForm,
      getFaucetStableCoinBalances,
    },
  };
});

export default useFaucet;
