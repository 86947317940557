import React from 'react';
import { useHistory } from 'react-router';

import { Text } from 'molecules/Text';

export default function LandingPageHeader() {
  const router = useHistory();

  const handleClick = () => {
    router.push('/app');
  };

  return (
    <>
      <div className=" bg-bannerBg bg-cover shadow-lg md:py-2 z-50 fixed w-full">
        <div className="flex justify-between items-center mx-auto px-10 py-3 md:py-4 md:max-w-6xl   ">
          <div className="flex justify-between items-center lg:space-x-8 md:space-x-2">
            <div className="flex justify-start">
              <div className="text-3xl md:text-6xl font-extrabold tracking-widest text-white">
                DFL
              </div>
            </div>
          </div>

          <div
            onClick={handleClick}
            className="flex  text-white items-center py-2 md:py-3 px-4 md:px-8 h-full justify-center font-semibold border border-white  rounded-2xl
           hover:bg-primary hover:text-white cursor-pointer"
          >
            <Text className="text-white font-bold text-sm md:text-md">Launch App</Text>
          </div>
        </div>
      </div>
    </>
  );
}
