import axios from 'axios';
import { getAccessToken } from './getTokens';

const serverUrl = process.env.REACT_APP_API_HOST;
const instance = axios.create({
  baseURL: serverUrl,
});
instance.interceptors.request.use(
  async function (config) {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (errorResponse) => {
    return errorResponse.response;
  },
);

const axiosService = {
  get: (endPoint, headers = {}) => {
    const config = { params: {}, headers: {} };
    if (!endPoint) {
      throw Error('endPoint is required params');
    } else {
      config.headers = headers;
      return instance.get(endPoint, config);
    }
  },
  post: (endPoint, data, headers = {}) => {
    if (!(endPoint || !data)) {
      throw Error('endPoint and data are required params');
    }
    return instance.post(endPoint, data, { headers });
  },
  put: (endPoint, data, headers = {}) => {
    if (!(endPoint || !data)) {
      throw Error('endPoint and data are required params');
    }
    return instance.put(endPoint, data, { headers });
  },
  patch: (endPoint, data, headers = {}) => {
    if (!(endPoint || !data)) {
      throw Error('endPoint and data are required params');
    }
    return instance.patch(endPoint, data, { headers });
  },
  delete: (endPoint, data, headers = {}) => {
    if (!endPoint) {
      throw Error('endPoint is required params');
    } else {
      return instance.delete(endPoint, { data: data, headers: headers });
    }
  },
};

export default axiosService;
