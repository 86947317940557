// module.exports = {
//   xy_token: '0xF7819Fb9400EA6b627eA1867FA331d1653CEf935',
//   gov_address: '0x4caF665fadA250CecD94F788a95353E0379dA1Eb',
//   gov_token_address: '0x68E564aac3a88f866d9F61626c55B892B9c7Dcf1',
//   stake_address: '0x51934A6D8B0Eb47095F6a3FE2C18303a2053D0D7',
//   gas_token: '0x1D94F3125908160fF21F8Bf1a6D257955EaeF16f',
//   deposit_Address: '0x47287151748A69163f74fDC7dAb4F91E64357804',
//   dai_token: '0x2d2619A3D55ADd8E08D37b077bDED77d5Bb81808',
//   escrow_Address: '0xB5802b78578B24080Bb93359f16cC6198a22D618',
//   USDT_Address: '0xa149C13e16E5b38E34F0107A71df69F0b2DBfD52',
//   USDC_Address: '0x69094d83c88eA92A0dca1eD2a922Be13eD74e552',
//   TOKENOMICS_ADDRESS: '0xf04FE9430f26CCA91B8c602a938086C6D7450A70',
//   FAUCETS_ADDRESS: '0x9cAA04Dc2D72a342dB72510dC581f32b55067357',
//   LP_TOKEN_ADDRESS: '0x058A0B1013F0a69bd77aDB79c135d22501719629',
//   STAKE_LP_CONTRACT_ADDRESS: '0x40A65D5641626740A7da04BB87bd2B1E10794dB9',
// };

module.exports = {
  xy_token: '0x5aFA10CA75c8456943d3C1deF4C210b69F22F14F',
  gov_address: '0x6786aFc0dbbe036e939b406D1440e433C66957a7',
  gov_token_address: '0xcb9a46764533999da2b48b659e1d300988b8e5cb',
  stake_address: '0x21833b1120fe287ab01b5c33ddaee9fd05b8d787',
  gas_token: '0x1D94F3125908160fF21F8Bf1a6D257955EaeF16f',
  deposit_Address: '0xb2762a23b3266dfaae130017b3765c2368f48f11',
  dai_token: '0x2d2619A3D55ADd8E08D37b077bDED77d5Bb81808',
  escrow_Address: '0x8bf1483454b277223112e1708a3a022274c2b4e0',
  USDT_Address: '0xa149C13e16E5b38E34F0107A71df69F0b2DBfD52',
  USDC_Address: '0x69094d83c88eA92A0dca1eD2a922Be13eD74e552',
  TOKENOMICS_ADDRESS: '0xf04FE9430f26CCA91B8c602a938086C6D7450A70',
  FAUCETS_ADDRESS: '0x9cAA04Dc2D72a342dB72510dC581f32b55067357',
  LP_TOKEN_ADDRESS: '0xC5b06D95aB6146AD5857716BE6c66318381C7Ea9',
  STAKE_LP_CONTRACT_ADDRESS: '0x40A65D5641626740A7da04BB87bd2B1E10794dB9',
};
// governance 
// 0x6786aFc0dbbe036e939b406D1440e433C66957a7
// gas token 
// 0xcb9a46764533999da2b48b659e1d300988b8e5cb
// erc20
// 0xC5b06D95aB6146AD5857716BE6c66318381C7Ea9
// xyz
// 0x5aFA10CA75c8456943d3C1deF4C210b69F22F14F
// deposit
// 0xb2762a23b3266dfaae130017b3765c2368f48f11
// escrow
// 0x8bf1483454b277223112e1708a3a022274c2b4e0
// gov stake
// 0x21833b1120fe287ab01b5c33ddaee9fd05b8d787