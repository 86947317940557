import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import { Text } from 'molecules/Text';
import { Modal } from 'atoms/Modal';

const LandingModalTemplate = ({ onClose, open, headerText, children }) => {
  return (
    <>
      <Modal isOpen={open} onClose={onClose} className="bg-white  absolute">
        {/* for above sm screen */}
        <div className="hidden bg-landingBgLeft  bg-no-repeat relative sm:block w-full  bg-white rounded-md max-h-screen overflow-scroll overflow-x-auto">
          <div
            className="flex shadow-lg fixed p-5 w-full px-10 bg-white justify-between border-b border-black mb-4 pb-2 px-0"
            style={{
              backgroundImage: 'linear-gradient(150deg, #3B63FF 0%, #AD29F2 100%)',
              boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 50%)',
            }}
          >
            <Text className="font-bold text-3xl text-center text-white">{headerText}</Text>

            <XIcon className="w-6 h-6 text-white font-bold cursor-pointer" onClick={onClose} />
          </div>

          <div className="mt-16 p-10  bg-landingBgRight bg-cover bg-no-repeat  bg-right-bottom" tabindex="0">
            {children}
          </div>
        </div>

        {/* for below sm screen */}
        <div className="w-full sm:hidden  bg-landingBgLeft  bg-no-repeat relative   bg-white rounded-md max-h-screen overflow-scroll overflow-x-auto">
          <div
            className="flex shadow-lg fixed p-5 w-full px-4 bg-white justify-between border-b border-black mb-4 pb-2 px-0"
            style={{
              backgroundImage: 'linear-gradient(150deg, #3B63FF 0%, #AD29F2 100%)',
              boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 50%)',
            }}
          >
            <Text className="font-bold text-md text-left text-white">{headerText}</Text>

            <XIcon className="w-6 h-6 text-white font-bold cursor-pointer" onClick={onClose} />
          </div>

          <div className="mt-16 p-4" t tabindex="0">{children}</div>
        </div>
      </Modal>
    </>
  );
};

export default LandingModalTemplate;
