import React from 'react';

export const LandingPageFooter = () => {
  return (
    <div
      style={{
        background: '#0309B2',
      }}
      className=" pt-20"
    >
      <section className="bg-landingFooterBg bg-cover bg-no-repeat md:w-10/12  mx-auto ">
        <div className="mx-auto px-5 md:px-10  md:max-w-6xl relative">
          <div>
            <div className="relative">
              <section
                data-aos="fade-up"
                className="rounded-lg absolute w-full px-5 md:px-10 py-10  md:py-20"
                style={{
                  top: '-20%',
                  boxShadow: '2px 18px 10px 12px rgb(0 0 0 / 50%)',
                  backgroundImage: 'linear-gradient(150deg, #3625DA 4%, #10085B 100%)',
                }}
              >
                <div className="md:flex w-full">
                  <div
                    className="md:w-1/2 text-xl md:text-left  md:text-3xl font-bold text-white "
                    data-aos="fade-right"
                  >
                    Keep Updated About DFL
                  </div>{' '}
                  <form
                    data-aos="fade-left"
                    method="post"
                    name="New Form"
                    className="md:w-1/2 flex  mt-3 md:mt-0 border-2 border-white rounded-md "
                  >
                    <input
                      className="w-full pl-2"
                      type="email"
                      name="post_id"
                      placeholder="Type Email"
                    />

                    <button type="submit" className="border rounded-md px-10 py-2 border-white">
                      <span className="text-white font-bold">Send</span>
                    </button>
                  </form>
                </div>
              </section>
              <section className=" ">
                <div className="ml-5 pt-24">
                  {/* <img
                    className="mt-10 md:mt-24 w-44 md:w-auto"
                    src="https://dfl.xyz/wp-content/uploads/elementor/thumbs/cropped-DFL-Logo-white-pe4kgsrycprp57lf4u6yoyq4ds3pdr42un0p28i160.png"
                    title="cropped-DFL-Logo-white.png"
                    alt="cropped-DFL-Logo-white.png"
                  />{' '} */}
                </div>
              </section>
              <section className="mt-10 md:mt-20 pb-5  md:pb-10">
                <div className="text-white text-left ml-5">
                  <p>© DFL – all rights reserved</p>{' '}
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
