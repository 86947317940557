import { useReducer, useCallback, useEffect } from 'react';
import Web3 from 'web3';
import { createContainer, createReducer, createAction } from 'utils/context';
import UserService from 'services/UserService';
import TxnService from 'services/TxnService';
import { toast } from 'react-toastify';
import axiosService from 'utils/api/axios';
import { hydrate, persist } from 'utils/persist';

const initialState = {
  sideTab: '',
  accountId: '',
  transactionLoading: false,
  user: '',
  transactions: [],
  transactionsByStatus: [],
  isConnected: false,
  authenticating: false,
  isLoginWalletOpen: false,
  submitLoading: false,
  isLoggedIn: false,
  loginUser: {},
  showAuthModal: '',
  tasklist: [],
};
const actions = {
  setAuthenticating: createAction('SET_AUTHENTICATING'),
  setSideTab: createAction('SET_SIDE_TAB'),
  setAccount: createAction('SET_ACCOUNT'),
  setUser: createAction('SET_USER'),
  setTransactions: createAction('SET_TRANSACTIONS'),
  setTransactionsByStatus: createAction('SET_TRANSACTIONS_BY_STATUS'),
  setTasklist: createAction('SET_TASKLIST'),
  setTransactionLoader: createAction('SET_TRANSACTION_LOADER'),
  setLoginWalletStatus: createAction('SET_LOGIN_WALLET'),
  setSubmitLoading: createAction('SET_SUBMIT_LOADING'),
  setLoginUser: createAction('SET_LOGIN_USER'),
  setShowAuthModal: createAction('SET_SHOW_AUTH_MODAL'),
};

const authReducer = createReducer({
  [actions.setLoginUser.toString()]: (state, { payload }) => ({
    ...state,
    loginUser: payload,
    isLoggedIn: Object.keys(payload).length,
  }),
  [actions.setSideTab.toString()]: (state, { payload }) => ({
    ...state,
    sideTab: payload,
  }),
  [actions.setAccount.toString()]: (state, { payload }) => ({
    ...state,
    accountId: payload,
  }),
  [actions.setTransactionLoader.toString()]: (state, { payload }) => ({
    ...state,
    transactionLoading: payload,
  }),
  [actions.setUser.toString()]: (state, { payload }) => ({
    ...state,
    user: payload,
    isConnected: true,
    authenticating: false,
  }),
  [actions.setTransactions.toString()]: (state, { payload }) => ({
    ...state,
    transactions: payload,
  }),
  [actions.setTransactionsByStatus.toString()]: (state, { payload }) => ({
    ...state,
    transactionsByStatus: payload,
  }),
  [actions.setTasklist.toString()]: (state, { payload }) => ({
    ...state,
    tasklist: payload,
  }),
  [actions.setAuthenticating.toString()]: (state, { payload }) => ({
    ...state,
    authenticating: payload,
  }),
  [actions.setLoginWalletStatus.toString()]: (state, { payload }) => ({
    ...state,
    isLoginWalletOpen: payload,
    authenticating: false,
    isConnected: true,
  }),
  [actions.setSubmitLoading.toString()]: (state, { payload }) => ({
    ...state,
    submitLoading: payload,
  }),
  [actions.setShowAuthModal.toString()]: (state, { payload }) => ({
    ...state,
    showAuthModal: payload,
  }),
});

export const { useContext: useAuth, Provider: AuthProvider } = createContainer(() => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const setSubmitLoading = (value) => {
    dispatch(actions.setSubmitLoading(value));
  };

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
    return;
  };
  const setLoginWalletStatus = (val) => {
    dispatch(actions.setLoginWalletStatus(val));
  };

  const loadBlockchainData = async () => {
    await loadWeb3();
    const web3 = window.web3;
    if (web3 !== undefined && web3.eth !== undefined) {
      const accounts = await web3.eth.getAccounts();
      dispatch(actions.setAccount(accounts[0]));
    }
  };

  const getAccountDetails = (accountId) => {
    dispatch(actions.setAuthenticating(true));

    UserService.account(accountId)
      .then((resolve) => {
        if (resolve?.status === 500) {
          toast.error('Connection Failed');
          dispatch(actions.setAuthenticating(false));
          dispatch(actions.setAccount(''));
        } 
        if (resolve?.status === 404) {
          dispatch(actions.setAuthenticating(true));
          dispatch(actions.setLoginWalletStatus(true));
        }else {
          if (Object.keys(resolve?.data?.data).length > 0) {
            dispatch(actions.setUser(resolve?.data?.data));
            toggleLoginWalletPopup('close');
          } else {
            dispatch(actions.setLoginWalletStatus(!state?.isLoginWalletOpen));

            setLoginWalletStatus(!state?.isLoginWalletOpen);
          }
        }
      })
      .catch((error) => {});
  };

  const getTxn = async (pageNum) => {
    if (state?.user?.avatar !== '' && state?.user?.avatar !== undefined) {
      await TxnService.fetchTransaction(state?.user?.avatar, pageNum).then((resolve) => {
        dispatch(actions.setTransactions(resolve?.data?.data));
      });
    }
  };

  const getTxnByStatus = async (pageNum) => {
    if (state?.user?.avatar !== '' && state?.user?.avatar !== undefined) {
      await TxnService.fetchTransactionByStatus(state?.user?.avatar, pageNum).then((resolve) => {
        dispatch(actions.setTransactionsByStatus(resolve?.data?.data));
      });
    }
  };

  const getTaskList = async (pageNum) => {
    await TxnService.fetchTransactionTaskList(state?.user?.avatar, pageNum).then((resolve) => {
      dispatch(actions.setTasklist(resolve?.data?.data));
    });
  };

  const sideTabHandler = (val) => {
    dispatch(actions.setSideTab(val));
  };

  const setTransactionLoader = (val) => {
    dispatch(actions.setTransactionLoader(val));
  };

  const setUser = (val) => {
    dispatch(actions.setUser(val));
  };

  const toggleLoginWalletPopup = (data) => {
    if (data === 'open') {
      if (state?.accountId !== '') {
        getAccountDetails();
      } else if (state?.isLoginWalletOpen) {
        setLoginWalletStatus(!state?.isLoginWalletOpen);
      }
    } else {
      if (data === 'close') {
        if (state?.isLoginWalletOpen) {
          setLoginWalletStatus(!state?.isLoginWalletOpen);
        }
      }
    }
  };

  useEffect(() => {
    // const user = localStorage.getItem('LOGIN_USER');
    const user = hydrate('LOGIN_USER', localStorage);
    if (user) {
      dispatch(actions.setLoginUser(user));
    }
  }, []);

  const setShowAuthModal = (val) => {
    dispatch(actions.setShowAuthModal(val));
  };

  const loginUser = useCallback(async (values) => {
    try {
      const { data } = await axiosService.post('api/v1/auth/login', {
        ...values,
      });
      if (data?.data) {
        persist('LOGIN_USER', data.data, 'localStorage');

        dispatch(actions.setLoginUser(data));
        setShowAuthModal('');
        toast.success('Login successfully');
      } else {
        toast.error(data?.message || 'Either email or password is wrong');
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || 'Either email or password is wrong');
    }
  }, []);

  const Signup = useCallback(
    async (values) => {
      delete values.confirmPassword;
      try {
        const { data } = await axiosService.post('/api/v1/auth/signup', {
          ...values,
        });
        toast.success(data.message);
        loginUser({ email: values?.email, password: values?.password });
      } catch (e) {
        toast.error(e?.response?.data?.message);
      }
    },
    [loginUser],
  );

  const logout = useCallback(() => {
    localStorage.clear();
    sideTabHandler(0);
    dispatch(actions.setLoginUser({}));
  }, []);

  return {
    state,
    actions: {
      loadWeb3,
      sideTabHandler,
      loadBlockchainData,
      setTransactionLoader,
      getAccountDetails,
      getTxn,
      toggleLoginWalletPopup,
      setSubmitLoading,
      setUser,
      loginUser,
      logout,
      setShowAuthModal,
      Signup,
      setLoginWalletStatus,
      getTaskList,
      getTxnByStatus,
    },
  };
});

export default useAuth;
