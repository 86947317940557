import { useReducer, useCallback } from 'react';
import Web3 from 'web3';
import { createContainer, createReducer, createAction } from 'utils/context';
import { convertFromWei } from 'utils/utility';

import ethAddressConfig from 'utils/abiSection/address/ethAddressConfig';
import useAuth from './AuthenticationContext';
import {
  daiTokenABI,
  tokenBalance1ABI,
  depositABI,
  escrowABI,
  stakeGovABI,
  govTokenABI,
  gasTokenABI,
  stakeABI,
  USDTABI,
  USDCABI,
  StakeLPContractABI,
  LPTokenABI,
} from 'utils/abiSection/abis';

const initialState = {
  lockedBalances: {},
  totalPoolBalance: '',
  MCTBalance: {},
  MGTBalance: {},
  MYTBalance: {},
  stakeBalance: {},
  stableCoinBalance: {},
};

const actions = {
  setLockedBalances: createAction('SET_LOCKED_BALANCES'),
  setTotalPoolBalance: createAction('SET_TOTAL_POOL_BALANCE'),
  setMCTBalance: createAction('SET_MCT_BALANCE'),
  setMGTBalance: createAction('SET_MGT_BALANCE'),
  setMYTBalance: createAction('SET_MYT_BALANCE'),
  setStableCoinBalance: createAction('SET_STABLE_COIN_BALANCE'),
  setStakeBalance: createAction('SET_STAke_BALANCE'),
};

const balanceReducer = createReducer({
  [actions.setLockedBalances]: (state, { payload }) => ({
    ...state,
    lockedBalances: payload,
  }),
  [actions.setTotalPoolBalance]: (state, { payload }) => ({
    ...state,
    totalPoolBalance: payload,
  }),
  [actions.setMCTBalance]: (state, { payload }) => ({
    ...state,
    MCTBalance: payload,
  }),
  [actions.setMGTBalance]: (state, { payload }) => ({
    ...state,
    MGTBalance: payload,
  }),
  [actions.setMYTBalance]: (state, { payload }) => ({
    ...state,
    MYTBalance: payload,
  }),
  [actions.setStableCoinBalance]: (state, { payload }) => ({
    ...state,
    stableCoinBalance: payload,
  }),
  [actions.setStakeBalance]: (state, { payload }) => ({
    ...state,
    stakeBalance: payload,
  }),
});

export const { useContext: useBalance, Provider: BalanceProvider } = createContainer(() => {
  const [state, dispatch] = useReducer(balanceReducer, initialState);

  const {
    state: { accountId },
  } = useAuth();

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
    return;
  };

  const getLockedBalance = useCallback(async () => {
    await loadWeb3();
    const web3 = window.web3;

    if (web3 !== undefined && web3.eth !== undefined) {
      let daiTokenABIObject = new web3.eth.Contract(daiTokenABI, ethAddressConfig.dai_token);
      let XYZTokenABIObject = new web3.eth.Contract(tokenBalance1ABI, ethAddressConfig.xy_token);

      let totalLockedBalance = await daiTokenABIObject?.methods
        ?.balanceOf(ethAddressConfig?.deposit_Address)
        ?.call();

      let daiLockedBalance = await daiTokenABIObject?.methods
        ?.balanceOf(ethAddressConfig?.deposit_Address)
        .call();

      let FLPLockedBalance = await XYZTokenABIObject?.methods
        ?.balanceOf(ethAddressConfig?.stake_address)
        .call();
      dispatch(
        actions.setLockedBalances({
          totalLockedBalance: convertFromWei(totalLockedBalance),
          daiLockedBalance: convertFromWei(daiLockedBalance),
          FLPLockedBalance: convertFromWei(FLPLockedBalance),
        }),
      );
    } else {
    }
  }, []);

  const getAllUnlockedBalance = async () => {
    const web3 = window.web3;
    if (web3 !== undefined && web3.eth !== undefined) {
      //For MCT Balance
      let depositABIObject = new web3.eth.Contract(depositABI, ethAddressConfig.deposit_Address);
      let XYZTokenABIObject = new web3.eth.Contract(tokenBalance1ABI, ethAddressConfig.xy_token);
      let unlockedMCTBalance = await XYZTokenABIObject.methods.balanceOf(accountId).call();
      let lockMCTBalance = await depositABIObject.methods.userInfoMCT(accountId).call();
      dispatch(
        actions.setMCTBalance({
          lockedMCT: lockMCTBalance.lockedMCT,
          unlockedMCT: unlockedMCTBalance,
        }),
      );

      // for total pool balance
      let totalBalance = await depositABIObject.methods.totalPoolBalance(accountId).call();
      dispatch(actions.setTotalPoolBalance(totalBalance));

      // For MGT Balance
      let govTokenABIObject = new web3.eth.Contract(
        govTokenABI,
        ethAddressConfig.gov_token_address,
      );
      let stakeGovABIObject = new web3.eth.Contract(stakeGovABI, ethAddressConfig.gov_address);
      let escrowABIObject = new web3.eth.Contract(escrowABI, ethAddressConfig.escrow_Address);

      let claimedMGTBalance = await govTokenABIObject.methods.balanceOf(accountId).call();
      let stakeGovPendingRewards = await stakeGovABIObject.methods.pendingRewards(accountId).call();
      let escowGovRewards = await escrowABIObject.methods.govRewards(accountId).call();
      let escowGovPendingRewards = await escrowABIObject.methods
        .pendpendingGovRewards(accountId)
        .call();

      let unClaimedMGTBalance =
        Number(stakeGovPendingRewards) + Number(escowGovRewards) + Number(escowGovPendingRewards);
      dispatch(
        actions.setMGTBalance({
          claimedMGTBalance: claimedMGTBalance ? convertFromWei(claimedMGTBalance, 'Ether') : 0,
          unClaimedMGTBalance: unClaimedMGTBalance
            ? convertFromWei(unClaimedMGTBalance, 'Ether')
            : 0,
          stakeGovPendingRewards: stakeGovPendingRewards
            ? convertFromWei(stakeGovPendingRewards, 'Ether')
            : 0,

          escowGovRewards: escowGovRewards ? convertFromWei(escowGovRewards, 'Ether') : 0,

          escowGovPendingRewards: escowGovPendingRewards
            ? convertFromWei(escowGovPendingRewards, 'Ether')
            : 0,
        }),
      );

      // For MYT Balance
      let gasTokenABIObject = new web3.eth.Contract(gasTokenABI, ethAddressConfig.gas_token);
      let stakeABIObject = new web3.eth.Contract(stakeABI, ethAddressConfig.stake_address);
      let claimedMYTBalance = await gasTokenABIObject.methods.balanceOf(accountId).call();
      let unClaimedMYTBalance = await stakeABIObject.methods.pendingRewards(accountId).call();
      dispatch(
        actions.setMYTBalance({
          claimedMYTBalance: claimedMYTBalance ? convertFromWei(claimedMYTBalance, 'Ether') : 0,
          unClaimedMYTBalance: unClaimedMYTBalance
            ? convertFromWei(unClaimedMYTBalance, 'Ether')
            : 0,
        }),
      );
    } else {
    }
  };

  const getStableCoinBalances = async () => {
    const web3 = window.web3;
    if (web3 !== undefined && web3.eth !== undefined) {
      const daiTokenABIObject = new web3.eth.Contract(daiTokenABI, ethAddressConfig.dai_token);
      const USDTABIObject = new web3.eth.Contract(USDTABI, ethAddressConfig.USDT_Address);
      const USDCABIObject = new web3.eth.Contract(USDCABI, ethAddressConfig.USDC_Address);
      const DAIBalance = await daiTokenABIObject.methods.balanceOf(accountId).call();
      const USDTBalance = await USDTABIObject.methods.balanceOf(accountId).call();
      const USDCBalance = await USDCABIObject.methods.balanceOf(accountId).call();
      dispatch(
        actions.setStableCoinBalance({
          DAIBalance: convertFromWei(DAIBalance),
          USDTBalance: convertFromWei(USDTBalance),
          USDCBalance: convertFromWei(USDCBalance),
        }),
      );
    }
  };

  const getAllStakeBalances = async () => {
    const web3 = window.web3;
    if (web3 !== undefined && web3.eth !== undefined) {
      const stakeGovABIObject = new web3.eth.Contract(stakeGovABI, ethAddressConfig.gov_address);
      const stakeLPContractObject = new web3.eth.Contract(
        StakeLPContractABI,
        ethAddressConfig.STAKE_LP_CONTRACT_ADDRESS,
      );
      const LPTokenObject = new web3.eth.Contract(LPTokenABI, ethAddressConfig.LP_TOKEN_ADDRESS);

      let stakeMGT = await stakeGovABIObject.methods.userInfo(accountId).call();

      let stakeETHDFLBalance = await stakeLPContractObject.methods.userInfo(0, accountId).call();
      let stakeETHFEESBalance = await stakeLPContractObject.methods.userInfo(1, accountId).call();
      let LPBalance = await LPTokenObject.methods.balanceOf(accountId).call();

      dispatch(
        actions.setStakeBalance({
          stakeDFLalance: convertFromWei(stakeMGT?.amount),
          LPBalance: convertFromWei(LPBalance),

          stakeETHDFLBalance: convertFromWei(stakeETHDFLBalance?.amount),
          stakeETHFEESBalance: convertFromWei(stakeETHFEESBalance?.amount),
        }),
      );
    }
  };

  return {
    state,
    actions: {
      loadWeb3,
      getLockedBalance,
      getAllUnlockedBalance,
      getStableCoinBalances,
      getAllStakeBalances,
    },
  };
});

export default useBalance;
