import React from 'react';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { FormikInput } from '../../../molecules/FormikInput/FormikInput';
import { useFormSubmitWithLoading } from '../../../utils/Hooks/useFormSubmitWithLoading';
import { Button } from 'molecules/Button';

import { validateRequiredPassword, validateRequiredEmail } from 'utils/validators';
import useAuth from 'contexts/AuthenticationContext';

const loginValidationSchema = yup.object().shape({
  email: validateRequiredEmail(),
  password: validateRequiredPassword(),
});

const defaultValues = {
  email: '',
  password: '',
};

const SignInForm = ({ onSubmit }) => {
  const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmit);
  const {
    actions: { setShowAuthModal },
  } = useAuth();
  return (
    <>
      <Formik
        initialValues={defaultValues}
        onSubmit={onSubmitHandler}
        validationSchema={loginValidationSchema}
      >
        {() => (
          <Form className="grid gap-5">
            <FormikInput label="Email" name="email" />
            <FormikInput label="Password" name="password" type="password" />

            <Button loading={loading}>Sign In</Button>
            <div className="my-1 border"></div>

            <Button
              type="button"
              className="w-full border-primary border bg-lightBlue text-white"
              onClick={() => setShowAuthModal('signup')}
            >
              Create Account
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SignInForm;
