import React from 'react';

import { CheckIcon } from '@heroicons/react/solid';
import { Button } from 'molecules/Button';
import ReactPlayer from 'react-player';
import ProcessSectionModal from './components/ProcessSectionModal';
import useVisibleState from 'utils/Hooks/useVisibleStates';

export default function ProcessSection() {
  const { visible, hide, show } = useVisibleState(false);

  const videoUrl = `https://www.youtube.com/watch?v=VGoRFpDhIVw`;

  return (
    <>
      <div className="h-full  0 pt-20 md:pt-32 " data-aos="fade-up">
        <div className="font-bold text-3xl md:text-5xl" data-aos="fade-up">
          Initial Process
        </div>
        <div className="flex justify-center items-center flex-col mx-auto  px-10 py-10 md:py-16 max-w-6xl  flex-col space-y-2 md:space-y-0">
          <div className="md:flex  w-full">
            <div className=" md:w-1/2">
              <div data-aos="fade-up-right">
                <div>
                  <div>
                    <ul>
                      <li className="flex mb-2">
                        <span>
                          <CheckIcon className="w-8 h-8 text-indigo-700 font-bold cursor-pointer" />
                        </span>
                        <span className="text-left font-thin text-gray-700 ml-2 text-lg">
                          Creating a Perfect Example For a De-centrally Run Football Club{' '}
                        </span>
                      </li>
                      <li className="flex">
                        <span>
                          <CheckIcon className="w-8 h-8 text-indigo-700 font-bold cursor-pointer" />
                        </span>
                        <span className="text-left font-regular ml-2 font-thin text-gray-700 text-lg">
                          Connecting Football Clubs to Liquidity Providers to Create The Perfect
                          Decentralized Funding Structure{' '}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="w-40 mt-5 md:mt-8">
                  <Button
                    className="shadow-corner border-4  px-3 py-2 w-full"
                    style={{
                      backgroundImage: 'linear-gradient(150deg, #3B63FF 0%, #AD29F2 100%)',
                      boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 50%)',
                    }}
                    onClick={show}
                  >
                    <span className="text-lg">Read More</span>
                  </Button>
                </div>
              </div>
            </div>
            <div
              className=" md:w-1/2 shadow-out  h-48 lg:h-80 mt-8 md:mt-0"
              data-aos="fade-up-left"
            >
              <ReactPlayer
                autoPlay
                url={videoUrl}
                width="100%"
                height="100%"
                borderRadius="8px"
                config={{
                  youtube: {
                    playerVars: { modestbranding: 1 },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ProcessSectionModal open={visible} onClose={hide} />
    </>
  );
}
