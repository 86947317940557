import { useReducer, useCallback } from 'react';
import { createContainer, createReducer } from 'utils/context';

import ethAddressConfig from 'utils/abiSection/address/ethAddressConfig';
import useAuth from './AuthenticationContext';
import { daiTokenABI, USDTABI, USDCABI, depositABI, tokenBalance1ABI } from 'utils/abiSection/abis';
import { toast } from 'react-toastify';
import useBalance from './BalanceContext';
import { convertToWei } from 'utils/utility';

const initialState = {};

const depositReducer = createReducer({});

export const { useContext: useDeposit, Provider: DepositProvider } = createContainer(() => {
  const [state] = useReducer(depositReducer, initialState);

  const {
    state: { accountId },
    actions: { setTransactionLoader, setSubmitLoading },
  } = useAuth();

  const {
    state: { MCTBalance },
    actions: { getAllUnlockedBalance, getStableCoinBalances },
  } = useBalance();

  const depositHandler = useCallback(
    async (ABIObject, lockValueBN, stakeRate, depositABIObject, unitSelectedVal) => {
      try {
        setTransactionLoader(true);
        setSubmitLoading(true);
        await ABIObject.methods
          .approve(ethAddressConfig?.deposit_Address, lockValueBN)
          .send({ from: accountId })
          .on('transactionHash', (hash) => {
            setTransactionLoader(true);
          })
          .on('receipt', (receipt) => {
            if (receipt.status) {
              setTransactionLoader(false);
              depositABIObject.methods
                .depositAndStake(Number(unitSelectedVal), lockValueBN, stakeRate)
                .send({ from: accountId })
                .on('transactionHash', (hash) => {
                  setTransactionLoader(true);
                })
                .then(async (receipt) => {
                  if (receipt.status) {
                    setTransactionLoader(false);
                    setSubmitLoading(false);
                    toast.success('Transaction Success');
                    await getStableCoinBalances();
                    await getAllUnlockedBalance();
                    return true;
                  }
                })
                .catch((err) => {
                  setTransactionLoader(false);
                  setSubmitLoading(false);
                });
            }
          });
      } catch (err) {
        setTransactionLoader(false);
        setSubmitLoading(false);
        toast.error(err.message);
      }
    },
    [
      getAllUnlockedBalance,
      getStableCoinBalances,
      accountId,
      setSubmitLoading,
      setTransactionLoader,
    ],
  );

  const handleDepositForm = useCallback(
    async (amount, stakeRate, unitSelectedVal) => {
      const web3 = window.web3;
      if (web3 !== undefined && web3.eth !== undefined) {
        const lockValueBN = convertToWei(amount);
        const depositABIObject = new web3.eth.Contract(
          depositABI,
          ethAddressConfig.deposit_Address,
        );
        const daiTokenABIObject = new web3.eth.Contract(daiTokenABI, ethAddressConfig.dai_token);
        const USDTABIObject = new web3.eth.Contract(USDTABI, ethAddressConfig.USDT_Address);
        const USDCABIObject = new web3.eth.Contract(USDCABI, ethAddressConfig.USDC_Address);

        if (unitSelectedVal === '0') {
          await depositHandler(
            daiTokenABIObject,
            lockValueBN,
            stakeRate,
            depositABIObject,
            unitSelectedVal,
          );
        } else if (unitSelectedVal === '1') {
          await depositHandler(
            USDTABIObject,
            lockValueBN,
            stakeRate,
            depositABIObject,
            unitSelectedVal,
          );
        } else {
          await depositHandler(
            USDCABIObject,
            lockValueBN,
            stakeRate,
            depositABIObject,
            unitSelectedVal,
          );
        }
      }
    },
    [depositHandler],
  );

  const handleReStakeDepositForm = useCallback(
    async (stakeRate) => {
      const web3 = window.web3;
      if (web3 !== undefined && web3.eth !== undefined) {
        const unlockedMCTBalance = web3.utils.fromWei(MCTBalance?.unlockedMCT.toString(), 'Ether');

        const result = (Number(unlockedMCTBalance) * Number(stakeRate)) / 100;

        const res = convertToWei(result);

        const depositABIObject = new web3.eth.Contract(
          depositABI,
          ethAddressConfig.deposit_Address,
        );
        const XYZTokenABIObject = new web3.eth.Contract(
          tokenBalance1ABI,
          ethAddressConfig.xy_token,
        );
        setSubmitLoading(true);
        try {
          await XYZTokenABIObject.methods
            .approve(ethAddressConfig.deposit_Address, res)
            .send({ from: accountId })
            .on('transactionHash', (hash) => {
              setTransactionLoader(true);
            })
            .on('receipt', (receipt) => {
              if (receipt.status) {
                setTransactionLoader(false);

                depositABIObject.methods
                  .stakeMCT(res)
                  .send({ from: accountId })
                  .on('transactionHash', (hash) => {
                    setTransactionLoader(true);
                  })
                  .then((receipt) => {
                    if (receipt.status) {
                      setTransactionLoader(false);
                      setSubmitLoading(false);
                      toast.success('Transaction Success');
                      getAllUnlockedBalance();
                    }
                  })
                  .catch((err) => {
                    setTransactionLoader(false);
                    setSubmitLoading(false);
                    toast.error('Transaction Failed');
                  });
              }
            });
        } catch (err) {
          setTransactionLoader(false);
          setSubmitLoading(false);
          toast.error(err.message);
        }
      }
    },
    [MCTBalance, getAllUnlockedBalance, setSubmitLoading, accountId, setTransactionLoader],
  );
  return {
    state,
    actions: { handleDepositForm, handleReStakeDepositForm },
  };
});

export default useDeposit;
