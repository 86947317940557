import { useCallback } from 'react';

export const useScrollTo = () => {
  const scrollTo = (element) => {
    element.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
  };

  const handleScroll = useCallback((index) => {
    scrollTo(document.getElementById(index));
  }, []);

  return { handleScroll };
};
