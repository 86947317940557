import React from 'react';
import { Button } from 'molecules/Button';

const MyRewardCard = ({ data, onSubmit, disabled }) => {
  return (
    <div className="flex justify-between shadow-sm text-left rounded-1 bg-secondary text-white">
      <div className=" px-5 py-4  w-full">
        <div className="flex justify-between items-center">
          <div className="font-sm font-bold">{data.heading}</div>
          <Button
            className="px-3 py-1 border font-medium text-xs text-gray-300 border-gray-300"
            disabled={disabled}
            onClick={() => onSubmit(true)}
            loading={data?.loading}
          >
            {data.buttonText}
          </Button>
        </div>
        <div className="flex mt-2  items-center">
          <div className="text-xs w-2/5 text-gray-300">{data.lockedLabel}</div>
          <div className="ml-2 border border-gray-400 w-3/5 px-2 py-1 text-xs">
            {parseFloat(data?.lockedAmount).toFixed(2)}
          </div>
        </div>
        <div className="flex mt-2 items-center">
          <div className="text-xs w-2/5 text-gray-300">{data.unLockedLabel}</div>

          <div className="ml-2 border border-gray-400 w-3/5 px-2 py-1 text-xs">
            {parseFloat(data?.unlockedAmount).toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyRewardCard;
