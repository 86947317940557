import React from 'react';
import ReactPlayer from 'react-player';

import {
  GlobeAltIcon,
  ViewListIcon,
  KeyIcon,
  UserIcon,
  ChartSquareBarIcon,
} from '@heroicons/react/solid';
import { useScrollTo } from 'utils/Hooks/useScrollTo';

export default function LandingPageBanner() {
  const videoUrl = `https://www.youtube.com/watch?v=4S-X7BaaHKY&t=1s`;
  const { handleScroll } = useScrollTo();

  return (
    <div className="bg-bgImage  bg-cover  ">
      <div className="h-full  bg-bannerBg bg-cover border-b-2 border-gray-100 py-2 pt-10 md:pt-28 ">
        <div className="flex justify-center items-center flex-col mx-auto  px-10 py-10 max-w-6xl  flex-col space-y-2 md:space-y-0">
          <div className=" h-50  items-center ">
            <div
              data-aos="zoom-out"
              className="md:text-center  text-white text-md md:text-xl font-extrabold shadow-out p-2 md:p-3 rounded-xl mb-5 md:mb-8 italic "
              style={{ textShadow: '2px 2px 2px black, 2px 2px 1px black' }}
            >
              DFL PROVIDES ACCESS TO LIQUIDITY FOR FOOTBALL CLUBS FROM ANYONE AND HELPS DECENTRALIZE
              THEIR GOVERNANCE STRUCTURE WITH ACTIVE INVOLVEMENT OF FANS.
            </div>

            <div className=" h-48 lg:h-96 w-full rounded-xl shadow-out">
              <ReactPlayer
                autoPlay
                width="100%"
                height="100%"
                url={videoUrl}
                className="rounded-xl"
                borderRadius="8px"
                config={{
                  youtube: {
                    playerVars: { modestbranding: 1 },
                  },
                }}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-5 gap-5  pt-10 md:pt-20 " data-aos="zoom-in-up">
            <div
              onClick={() => handleScroll('1')}
              className="flex  flex-1 flex-col justify-center w- items-center transform-none	  "
            >
              <div className="h-20 w-20 md:h-24 md:w-24 lg:h-24 lg:w-24 flex items-center justify-center   bg-white rounded-full transform transition duration-100 hover:scale-110  ">
                <GlobeAltIcon
                  data-aos="flip-right"
                  className="h-14 w-14 md:w-16 md:h-16 text-indigo-700 font-bold cursor-pointer "
                />
              </div>
              <div className="mt-3 md:mt-5 h-12 md:h-20 text-md md:text-xl lg:text-xl font-bold text-white">
                OVERVIEW
              </div>
            </div>
            <div
              onClick={() => handleScroll('2')}
              className="flex flex-1 flex-col justify-center items-center"
            >
              <div className=" h-20 w-20  md:h-24 md:w-24 lg:h-24 lg:w-24 flex items-center justify-center   bg-white rounded-full transform transition duration-100 hover:scale-110">
                <ViewListIcon
                  data-aos="flip-right"
                  className="h-14 w-14 md:w-16 md:h-16 text-indigo-700 font-bold cursor-pointer"
                />
              </div>
              <div className="mt-3 md:mt-5 h-12 md:h-20 text-md md:text-xl lg:text-xl font-bold text-white">
                INITIAL PROCESS
              </div>
            </div>
            <div
              onClick={() => handleScroll('3')}
              className="flex flex-1 flex-col justify-center items-center"
            >
              <div className=" h-20 w-20  md:h-24 md:w-24 lg:h-24 lg:w-24 flex items-center justify-center   bg-white rounded-full transform transition duration-100 hover:scale-110">
                <KeyIcon
                  data-aos="flip-right"
                  className="h-14 w-14 md:w-16 md:h-16 text-indigo-700 font-bold cursor-pointer"
                />
              </div>
              <div className="mt-3 md:mt-5 h-12 md:h-20 text-md md:text-xl lg:text-xl font-bold text-white">
                KEY COMPONENTS OF DFL
              </div>
            </div>
            <div
              onClick={() => handleScroll('4')}
              className="flex  flex-1 flex-col justify-center w- items-center"
            >
              <div className=" h-20 w-20  md:h-24 md:w-24 lg:h-24 lg:w-24 flex items-center justify-center   bg-white rounded-full transform transition duration-100 hover:scale-110">
                <UserIcon
                  data-aos="flip-right"
                  className="h-14 w-14 md:w-16 md:h-16 text-indigo-700 font-bold cursor-pointer"
                />
              </div>
              <div className="mt-3 md:mt-5 h-12 md:h-20 text-md md:text-xl lg:text-xl font-bold text-white">
                PROTOCOL FUNCTIONALITY
              </div>
            </div>
            <div
              onClick={() => handleScroll('5')}
              className="flex flex-1 flex-col justify-center items-center"
            >
              <div className=" h-20 w-20  md:h-24 md:w-24 lg:h-24 lg:w-24 flex items-center justify-center   bg-white rounded-full transform transition duration-100 hover:scale-110">
                <ChartSquareBarIcon
                  data-aos="flip-right"
                  className="h-14 w-14 md:w-16 md:h-16 text-indigo-700 font-bold cursor-pointer"
                />
              </div>
              <div className="mt-3 md:mt-5 h-12 md:h-20 text-md md:text-xl lg:text-xl font-bold text-white">
                FLOW CHART
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
