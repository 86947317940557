import { useReducer } from 'react';
import { createContainer, createReducer } from 'utils/context';

import ethAddressConfig from 'utils/abiSection/address/ethAddressConfig';
import useAuth from './AuthenticationContext';
import { stakeGovABI, govTokenABI, StakeLPContractABI, LPTokenABI } from 'utils/abiSection/abis';
import { toast } from 'react-toastify';
import useBalance from './BalanceContext';
import { convertToWei } from 'utils/utility';

const initialState = {};

const stakingReducer = createReducer({});

export const { useContext: useStaking, Provider: StakingProvider } = createContainer(() => {
  const [state] = useReducer(stakingReducer, initialState);

  const {
    state: { accountId },
    actions: { setTransactionLoader, setSubmitLoading },
  } = useAuth();

  const {
    actions: { getAllStakeBalances, getAllUnlockedBalance },
  } = useBalance();

  const handleStakeForm = async (stakeValue, poolId) => {
    const web3 = window.web3;
    if (web3 !== undefined && web3.eth !== undefined) {
      const stakeGovABIObject = new web3.eth.Contract(stakeGovABI, ethAddressConfig.gov_address);
      const govTokenABIObject = new web3.eth.Contract(
        govTokenABI,
        ethAddressConfig.gov_token_address,
      );

      const convertStakeValue = window.web3.utils.toWei(stakeValue.toString(), 'Ether');
      const stakeLPContractObject = new web3.eth.Contract(
        StakeLPContractABI,
        ethAddressConfig.STAKE_LP_CONTRACT_ADDRESS,
      );

      const LPTokenObject = new web3.eth.Contract(LPTokenABI, ethAddressConfig.LP_TOKEN_ADDRESS);
      setSubmitLoading(true);
      if (poolId) {
        try {
          await LPTokenObject.methods
            .approve(ethAddressConfig.STAKE_LP_CONTRACT_ADDRESS, convertStakeValue)
            .send({ from: accountId })
            .on('transactionHash', (hash) => {
              stakeLPContractObject.methods
                .deposit(convertStakeValue, Number(poolId))

                .send({ from: accountId })
                .on('transactionHash', (hash) => {
                  setTransactionLoader(true);
                  setSubmitLoading(true);
                })
                .then((receipt) => {
                  if (receipt.status) {
                    getAllStakeBalances();
                    setTransactionLoader(false);
                    setSubmitLoading(false);
                    toast.success('Transaction Success');
                  }
                })
                .catch((err) => {
                  setTransactionLoader(false);
                  setSubmitLoading(false);
                  toast.error('Transaction Failed');
                });
            });
        } catch (err) {
          setTransactionLoader(false);
          setSubmitLoading(false);
          toast.error(err.message);
        }
      } else {
        try {
          await govTokenABIObject.methods
            .approve(ethAddressConfig.gov_address, convertStakeValue)
            .send({ from: accountId })
            .on('transactionHash', (hash) => {
              stakeGovABIObject.methods
                .deposit(convertStakeValue)

                .send({ from: accountId })
                .on('transactionHash', (hash) => {
                  setTransactionLoader(true);
                  setSubmitLoading(true);
                })
                .then((receipt) => {
                  if (receipt.status) {
                    getAllStakeBalances();
                    setTransactionLoader(false);
                    getAllUnlockedBalance();
                    setSubmitLoading(false);

                    toast.success('Transaction Success');
                  }
                })
                .catch((err) => {
                  setTransactionLoader(false);
                  setSubmitLoading(false);
                  toast.error('Transaction Failed');
                });
            });
        } catch (err) {
          setTransactionLoader(false);
          setSubmitLoading(false);
          toast.error(err.message);
        }
      }
    }
  };

  const handleUnStakeForm = async (unStakeValue, poolId) => {
    const web3 = window.web3;
    if (web3 !== undefined && web3.eth !== undefined) {
      const stakeGovABIObject = new web3.eth.Contract(stakeGovABI, ethAddressConfig.gov_address);

      const convertUnStakeValue = convertToWei(unStakeValue);
      const stakeLPContractObject = new web3.eth.Contract(
        StakeLPContractABI,
        ethAddressConfig.STAKE_LP_CONTRACT_ADDRESS,
      );
      if (poolId) {
        try {
          await stakeLPContractObject.methods
            .withdraw(convertUnStakeValue, Number(poolId))
            .send({ from: accountId })
            .on('transactionHash', (hash) => {
              setTransactionLoader(true);
            })
            .then((receipt) => {
              if (receipt.status) {
                getAllStakeBalances();
                setTransactionLoader(false);

                toast.success('Transaction Success');
              }
            })
            .catch((err) => {
              setTransactionLoader(false);

              toast.error('Transaction Failed');
            });
        } catch (err) {
          setTransactionLoader(false);

          toast.error(err.message);
        }
      } else {
        try {
          await stakeGovABIObject.methods
            .withdraw(convertUnStakeValue)
            .send({ from: accountId })
            .on('transactionHash', (hash) => {
              setTransactionLoader(true);
            })
            .then((receipt) => {
              if (receipt.status) {
                getAllStakeBalances();
                setTransactionLoader(false);
                getAllUnlockedBalance();
                toast.success('Transaction Success');
              }
            })
            .catch((err) => {
              setTransactionLoader(false);

              toast.error('Transaction Failed');
            });
        } catch (err) {
          setTransactionLoader(false);

          toast.error(err.message);
        }
      }
    }
  };

  return {
    state,
    actions: { handleStakeForm, handleUnStakeForm },
  };
});

export default useStaking;
